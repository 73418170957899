<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto mt-1 content-outer-container">
      <div class="mx-auto mainContainer wrap-burnList">
        <div class="titleContainer">
          <div class="title">
            <div class="textTitle">
              <img
                class="mr-2"
                style="vertical-align: sub"
                src="@/assets/images/gift.svg"
                alt=""
              />{{ lbl['plan-reward-point-burn-tab'] }}
            </div>
          </div>
          <div class="search d-flex">
            <div class="searchContainer">
              <a-input
                type="text"
                class="searchInput"
                v-model="searchInput"
                @keyup.enter="onEnterInput($event)"
                @change="onChangeInput($event)"
                autocomplete="off"
              />
              <img
                @click="onEnterInput($event)"
                :src="require('@/assets/images/Search.png')"
                alt=""
              />
            </div>
            <a-button
              type="primary"
              @click="handleCreateCampaings()"
              :disabled="isLimit || checkAppId"
            >
              <img
                :src="require('@/assets/images/btn/plus-square.svg')"
                class="mr-2"
                alt=""
              />
              {{ lbl['plan-reward-point-burn-list-add-button'] }}
            </a-button>
          </div>
        </div>
        <hr />
        <div class="filterContainer nopad mt-4">
          <div :class="['filterMain', showFilterMain ? 'Active' : '']">
            <div class="filter" @click="toggle('showFilterMain')">
              {{ lbl['user-permissions-filter'] }}
              <img :src="require('@/assets/images/filter_list.png')" alt="" />
            </div>
            <div class="spaceHover"></div>
            <div class="filterDropdown">
              <div class="filterLeft">
                <div
                  :class="['filterList', item === subFilter ? 'Active' : '']"
                  v-for="item in Object.keys(filter)"
                  @click="changeSubFilter(item)"
                  :key="item"
                >
                  {{ filter[item].label }}
                  <img
                    :src="require('@/assets/images/navigate_next.png')"
                    alt=""
                  />
                </div>
              </div>
              <div class="filterRight">
                <div v-for="item in filter[subFilter].input" :key="item">
                  <div
                    v-if="filter[subFilter].type === 'checkbox'"
                    class="filterList"
                    @click="selectFilter(item)"
                  >
                    <CheckBox
                      :label="item"
                      :value="item"
                      :check="filter[subFilter].value"
                      type="filter"
                    />
                  </div>
                  <div
                    v-if="filter[subFilter].type === 'text'"
                    class="filterList input"
                  >
                    <input
                      type="text"
                      :placeholder="item"
                      v-model="filter[subFilter].value"
                    />
                  </div>
                </div>
              </div>
              <div class="btnFilter">
                <div @click="resetFilter()">
                  {{ lbl['user-permissions-reset'] }}
                </div>
                <button @click="searchFilter()">
                  {{ lbl['user-permissions-ok'] }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="filterItem"
            v-for="(item, index) in Object.keys(allFilter)"
            :key="'filter' + index"
          >
            {{ allFilter[item] }}
            <img
              @click="removeFilter(item)"
              :src="require('@/assets/images/close.png')"
              alt=""
            />
          </div>
        </div>
        <!-- Table Campaings List -->
        <div class="tableContainer wrap-table">
          <a-skeleton v-if="isLoading" active />
          <div v-else>
            <a-table
              row-key="ID"
              :rowClassName="
                record =>
                  record.Status != 3 && record.Status != 5 ? 'disablestyle' : ''
              "
              :columns="listCampaings"
              :data-source="objBurnCampaings"
              :pagination="{
                pageSize: pageSizeCampaings,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
                hideOnSinglePage: false,
                pageSizeOptions: pageSizeOptions,
                defaultCurrent: 1,
                showSizeChanger: true,
                hideOnSinglePage: false,
                onChange: onChangePageCampaings,
                onShowSizeChange: onShowSizeChange,
              }"
            >
              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px"
              >
                <a-input
                  v-ant-ref="c => (searchInput = c)"
                  :placeholder="lbl['google-map-search-box-placeholder']"
                  :value="searchName[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="
                    e => {
                      searchName[0] = e.target.value

                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="() => handleSearch(selectedKeys, confirm, column)"
                >
                  {{ lbl['dashboard-search-box-button'] }}
                </a-button>
                <a-button
                  size="small"
                  style="width: 90px"
                  @click="() => handleReset(clearFilters)"
                >
                  {{ lbl['confirm-box-cancel-button'] }}
                </a-button>
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
              <template slot="sequence" slot-scope="text, record, index">
                <draggable
                  :list="listsequence[index]"
                  group="people"
                  :id="index"
                  @end="onEnd($event, index)"
                >
                  <div
                    v-for="sequence in listsequence[index]"
                    :key="sequence.id"
                    class="btn-sequence"
                  >
                    <span />
                    <span />
                    <span />
                  </div>
                </draggable>
              </template>
              <template slot="date" slot-scope="text">
                {{ dateShortFormatUTC(text.StartDate) }} -
                {{ dateShortFormatUTC(text.EndDate) }}
              </template>
              <template slot="viewcode" slot-scope="text">
                <div class="viewcode" @click="handleSearchSerialCodeList(text)">
                  {{ lbl['manage-usr-view'] }}
                </div>
              </template>
              <template slot="status" slot-scope="text, record">
                <span :class="statusColor(record.Status)" class="status">
                  {{ text }}
                </span>
              </template>
              <template slot="campaign" slot-scope="text">
                <div class="reward">
                  <img
                    :src="text.ImageUrl + `?v=` + new Date().getTime()"
                    alt=""
                    @error="imageUrlAlt"
                    class="reward-image"
                  />
                  <div class="reward-name">
                    {{ languageLocale == 'th' ? text.Name : text.NameEN }}
                  </div>
                </div>
              </template>
              <template slot="action" slot-scope="text, record">
                <div class="d-flex justify-start">
                  <a
                    type="primary"
                    class="btn-action d-flex justify-center align-center mr-2"
                    @click="handleEditCampaings(record)"
                  >
                    <img
                      class="mr-2"
                      alt=""
                      src="@/assets/images/planreward/plan_edit.svg"
                    />
                    {{ lbl['account-setup-button-edit'] }}
                  </a>
                  <a
                    type="primary"
                    class="btn-action d-flex justify-center align-center mr-2"
                    :class="{
                      'disable-button': isLimit || checkAppId,
                    }"
                    @click="handleDuplicateCampaings(record)"
                    :disabled="isLimit || checkAppId"
                  >
                    <img
                      class="mr-2"
                      src="@/assets/images/planreward/plan_Duplicate.svg"
                      alt=""
                    />
                    {{ lbl['plan-reward-button-duplicate'] }}
                  </a>
                  <a
                    v-if="record.Status != 3"
                    type="primary"
                    class="btn-action d-flex justify-center align-center"
                    @click="handleDeleteCampaings(record)"
                  >
                    <img
                      src="@/assets/images/planreward/plan_Trash.svg"
                      alt=""
                    />
                  </a>
                </div>
              </template>
            </a-table>
          </div>
        </div>

        <!-- Modal -->
        <!-- SerialCodeList-->
        <a-modal
          id="modal_serial_code_list"
          v-model="visibleSerialCodeList"
          :title="lbl['view-code-popup']"
          :footer="null"
          centered
          class="modal-serial-codelist"
          :class="[currentLocale]"
        >
          <a-table
            row-key="Code"
            :columns="columnsSerialCodeList"
            :data-source="objSerialCodeList"
            :pagination="{
              pageSize: defaultPageSize,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} ${lbl['pagination-of-text']} ${total}`,
              pageSizeOptions: pageSizeOptions,
              defaultCurrent: 1,
              showSizeChanger: true,
              hideOnSinglePage: false,
              onChange: onChangePageSerial,
            }"
          >
            <template slot="status" slot-scope="text, record">
              {{ record.Active ? lbl['active'] : lbl['inactive'] }}
            </template>
            <template slot="isdata" slot-scope="text">
              {{ text ? text : '-' }}
            </template>
            <template slot="date" slot-scope="text">
              {{ text ? dateShortFormatUTC(text) : '' }}
              {{ text ? timeFormatUTC(text) : '' }}
            </template>
          </a-table>
          <div class="div-export-serialcodelist mt-2">
            <ExportExcel
              :text="lbl['dashboard-insight-export-excel-button']"
              @clickButton="exportSerialCodeListUrl()"
            />
          </div>
        </a-modal>

        <!-- CampaignDetail-->
        <a-modal
          id="modal_campaign_detail"
          v-model="campaignDetail"
          :title="
            isClickCreateCampaings
              ? lbl['btn_create_reward-smarttarget']
              : lbl['plan-reward-button-edit']
          "
          :footer="null"
          centered
          @cancel="closeModalCampaignDetail()"
          class="modal-campaign-modal"
          :class="[currentLocale]"
        >
          <!-- <a-form :form="formAddCampaign"> -->
          <a-form :form="form">
            <!-- Name -->
            <div class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-name'] }}
                </div>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item :label="lbl['eng-label']">
                  <a-input
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateNameEng',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-name-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                  <a-input
                    type="text"
                    :disabled="
                      objCampaign.Status != '1' && objCampaign.Status != '2'
                    "
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditNameEng',
                      {
                        initialValue: objCampaingsSecect.NameEN,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-name-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item
                  :label="isPhilippines ? lbl['eng-label'] : lbl['th-label']"
                >
                  <a-input
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateNameTh',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-name-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  >
                  </a-input>
                  <a-input
                    type="text"
                    :disabled="
                      objCampaign.Status != '1' && objCampaign.Status != '2'
                    "
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditNameTh',
                      {
                        initialValue: objCampaingsSecect.Name,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-name-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <!-- Detail -->
            <div class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-detail'] }}
                </div>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item :label="lbl['eng-label']">
                  <a-textarea
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateDetailEng',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-detail-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                  <a-textarea
                    type="text"
                    :disabled="
                      objCampaign.Status != '1' && objCampaign.Status != '2'
                    "
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditDetailEng',
                      {
                        initialValue: objCampaingsSecect.DetailEN,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-detail-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item
                  :label="isPhilippines ? lbl['eng-label'] : lbl['th-label']"
                >
                  <a-textarea
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateDetailTh',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-detail-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  >
                  </a-textarea>
                  <a-textarea
                    type="text"
                    :disabled="
                      objCampaign.Status != '1' && objCampaign.Status != '2'
                    "
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditDetailTh',
                      {
                        initialValue: objCampaingsSecect.Detail,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-detail-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  >
                  </a-textarea>
                </a-form-item>
              </div>
            </div>
            <!-- TermsAndConditions -->
            <div class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative; padding-right: 0px"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-description'] }}
                </div>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item :label="lbl['eng-label']">
                  <a-textarea
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateConditionEN',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-description-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                  <a-textarea
                    type="text"
                    :disabled="
                      objCampaign.Status != '1' && objCampaign.Status != '2'
                    "
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditConditionEN',
                      {
                        initialValue: objCampaingsSecect.ConditionEN,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-description-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item
                  :label="isPhilippines ? lbl['eng-label'] : lbl['th-label']"
                >
                  <a-textarea
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateConditionTh',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-description-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  >
                  </a-textarea>
                  <a-textarea
                    type="text"
                    :disabled="
                      objCampaign.Status != '1' && objCampaign.Status != '2'
                    "
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditConditionTh',
                      {
                        initialValue: objCampaingsSecect.TermsAndConditions,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-description-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  >
                  </a-textarea>
                </a-form-item>
              </div>
            </div>
            <!-- Image -->
            <div class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-image'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <div>
                  <div
                    class="profile-image"
                    @click="clickSelectImageCampaings()"
                  ></div>
                  <input
                    :disabled="
                      isClickEditCampaings &&
                      objCampaign.Status != '1' &&
                      objCampaign.Status != '2'
                    "
                    class="d-none"
                    type="file"
                    ref="inputCampaingsImage"
                    accept="image/png, image/jpeg"
                    @input="onInputImageCampaings($event)"
                  />
                  <div for="ImgFile" style="text-align: left">
                    <div v-if="isClickCreateCampaings">
                      <span @click="clickUploadImage()">
                        <img
                          v-if="!pathImage"
                          :src="require('@/assets/images/blank-photo.png')"
                          alt=""
                          style="width: 100px; cursor: pointer"
                        />
                        <img
                          v-if="pathImage"
                          :src="pathImage"
                          alt=""
                          style="width: 100px; height: 100px; cursor: pointer"
                        />
                      </span>
                    </div>
                    <div v-if="isClickEditCampaings">
                      <span @click="clickUploadImage()">
                        <img
                          v-if="!objCampaingsSecect.ImageUrl"
                          :src="require('@/assets/images/blank-photo.png')"
                          alt=""
                          style="width: 100px; cursor: pointer"
                        />
                        <img
                          v-if="objCampaingsSecect.ImageUrl"
                          :src="objCampaingsSecect.ImageUrl"
                          alt=""
                          style="width: 100px; height: 100px; cursor: pointer"
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    v-show="errimgUpload"
                    style="color: red; text-align: left"
                  >
                    {{
                      lbl[
                        'plan-reward-stamp-campaign-manage-image-required-message'
                      ]
                    }}
                  </div>
                  <div class="">
                    <div class="div1 txt-left">
                      <div class="">
                        {{ this.maximumPhotoSizeUploadInMB }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- PointPerUnit -->
            <div v-if="storeType == 'point'" class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['user-level-number-point'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <a-form-item>
                  <div class="div1">
                    <a-input-number
                      :min="0"
                      v-if="isClickCreateCampaings"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      v-decorator="[
                        'CreatePointPerUnit',
                        {
                          initialValue: pointPerUnit,
                          rules: [
                            {
                              required: true,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-point-use-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                    <a-input-number
                      :min="0"
                      :disabled="
                        objCampaign.Status != '1' && objCampaign.Status != '2'
                      "
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      v-if="isClickEditCampaings"
                      v-decorator="[
                        'EditPointPerUnit',
                        {
                          initialValue: objCampaingsSecect.PointPerUnit,
                          rules: [
                            {
                              required: true,
                              message:
                                lbl[
                                  'plan-reward-stamp-campaign-manage-description-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                  </div>
                  <div class="div1">
                    {{ lbl['user-level-number-point'] }}
                  </div>
                </a-form-item>
              </div>
            </div>
            <!-- PricePerUnit -->
            <div
              v-if="storeType == 'point' && !isPhilippines"
              class="row field-row"
            >
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['user-level-price-per-unit'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <a-form-item>
                  <div class="div1">
                    <a-input-number
                      :min="0"
                      v-if="isClickCreateCampaings"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      v-decorator="[
                        'CreatePricePerUnit',
                        {
                          initialValue: pricePerUnit,
                          rules: [
                            {
                              required: true,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-price-per-unit-use-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                    <a-input-number
                      :min="0"
                      :disabled="
                        objCampaign.Status != '1' && objCampaign.Status != '2'
                      "
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      v-if="isClickEditCampaings"
                      v-decorator="[
                        'EditPricePerUnit',
                        {
                          initialValue: objCampaingsSecect.PricePerUnit,
                          rules: [
                            {
                              required: true,
                              message:
                                lbl[
                                  'plan-reward-stamp-campaign-manage-description-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                  </div>
                  <div class="div1">
                    {{ lbl['user-level-number-baht'] }}
                  </div>
                </a-form-item>
                <div class="has-error-price">
                  *{{ lbl['can-edit-later-saving'] }}
                </div>
              </div>
            </div>
            <!-- Quota -->
            <div class="row field-row quota">
              <!-- QuotaTotal -->
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-total-quota'] }}
                </div>
              </div>
              <div class="col-md-4 field-column" style="position: relative">
                <a-form-item>
                  <div class="div1">
                    <a-radio-group
                      :disabled="
                        isClickEditCampaings &&
                        objCampaign.Status != '1' &&
                        objCampaign.Status != '2'
                      "
                      v-model="optionQuotaTotal"
                      @change="changeCreateQuotaTotal"
                    >
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-max-per-person-unlimit'
                          ]
                        }}
                      </a-radio>
                      <a-radio :value="true">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-max-per-person-limit'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-form-item>
                <a-form-item>
                  <div class="div1">
                    <a-input-number
                      :min="1"
                      v-if="isClickCreateCampaings"
                      :disabled="!optionQuotaTotal"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      v-bind:class="{ active: !optionQuotaTotal }"
                      v-decorator="[
                        'CreateQuotaTotal',
                        {
                          initialValue: quotaTotal,
                          rules: [
                            {
                              required: optionQuotaTotal,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-total-quota-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                    <a-input-number
                      :min="1"
                      v-if="isClickEditCampaings"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      :disabled="
                        !optionQuotaTotal ||
                        (objCampaign.Status != '1' && objCampaign.Status != '2')
                      "
                      v-bind:class="{ active: !optionQuotaTotal }"
                      v-decorator="[
                        'EditQuotaTotal',
                        {
                          initialValue: objCampaingsSecect.Quantity,
                          rules: [
                            {
                              required: optionQuotaTotal,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-total-quota-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                  </div>
                </a-form-item>
              </div>
              <!-- QuotaPerson -->
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-max-per-person'] }}
                </div>
              </div>
              <div class="col-md-4 field-column" style="position: relative">
                <a-form-item>
                  <div class="div1">
                    <a-radio-group
                      :disabled="
                        isClickEditCampaings &&
                        objCampaign.Status != '1' &&
                        objCampaign.Status != '2'
                      "
                      v-model="optionQuotaPerson"
                      @change="changeCreateQuotaPerson"
                    >
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-max-per-person-unlimit'
                          ]
                        }}
                      </a-radio>
                      <a-radio :value="true">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-max-per-person-limit'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-form-item>
                <a-form-item>
                  <div class="div1">
                    <a-input-number
                      :min="1"
                      v-if="isClickCreateCampaings"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      :disabled="!optionQuotaPerson"
                      v-bind:class="{ active: !optionQuotaPerson }"
                      v-decorator="[
                        'CreateQuotaPerson',
                        {
                          initialValue: quotaPerson,
                          rules: [
                            {
                              required: optionQuotaPerson,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-total-quota-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                    <a-input-number
                      :min="1"
                      v-if="isClickEditCampaings"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      :disabled="
                        !optionQuotaPerson ||
                        (objCampaign.Status != '1' && objCampaign.Status != '2')
                      "
                      v-bind:class="{ active: !optionQuotaPerson }"
                      v-decorator="[
                        'EditQuotaPerson',
                        {
                          initialValue: objCampaingsSecect.RedeemMostPerPerson,
                          rules: [
                            {
                              required: optionQuotaPerson,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-total-quota-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                  </div>
                </a-form-item>
              </div>
            </div>
            <!-- Display Campaign -->
            <div class="row field-row quota">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-displaycampaign'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <a-form-item>
                  <div v-if="isClickCreateCampaings" class="div1">
                    <a-radio-group v-model="skipDisplayCampaignPage">
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-show-displaycampaign'
                          ]
                        }}
                      </a-radio>
                      <a-radio :value="true">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-notshow-displaycampaign'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                  <div v-if="isClickEditCampaings" class="div1">
                    <a-radio-group
                      :disabled="
                        objCampaign.Status != '1' && objCampaign.Status != '2'
                      "
                      v-model="objCampaingsSecect.Extra"
                    >
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-show-displaycampaign'
                          ]
                        }}
                      </a-radio>
                      <a-radio :value="true">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-notshow-displaycampaign'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-form-item>
              </div>
            </div>
            <!-- Interval -->
            <div class="row field-row interval">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-point-burn-manage-interval'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <a-form-item>
                  <a-checkbox
                    :disabled="
                      isClickEditCampaings &&
                      objCampaign.Status != '1' &&
                      objCampaign.Status != '2'
                    "
                    :checked="optionCheckInterval"
                    @change="handleCheckInterval"
                  >
                  </a-checkbox>
                </a-form-item>
                <a-form-item>
                  <a-input-number
                    :min="1"
                    v-if="isClickCreateCampaings"
                    :disabled="!optionCheckInterval"
                    v-bind:class="{ active: !optionCheckInterval }"
                    v-decorator="[
                      'CreateInterval',
                      {
                        initialValue: periodInterval,
                        rules: [
                          {
                            required: optionCheckInterval,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-interval-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                  <a-input-number
                    :min="1"
                    v-if="isClickEditCampaings"
                    :disabled="
                      !optionCheckInterval ||
                      (objCampaign.Status != '1' && objCampaign.Status != '2')
                    "
                    v-bind:class="{ active: !optionCheckInterval }"
                    v-decorator="[
                      'EditInterval',
                      {
                        initialValue:
                          objCampaingsSecect.RedeemMostPerPersonInPeriod,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-description-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
                <a-form-item>
                  <a-select
                    v-if="isClickCreateCampaings"
                    :disabled="!optionCheckInterval"
                    v-bind:class="{ active: !optionCheckInterval }"
                    v-decorator="[
                      'IntervalPeriod',
                      {
                        rules: [
                          {
                            required: optionCheckInterval,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-interval-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                    style="width: 183px"
                  >
                    <a-select-option value="day"> Day </a-select-option>
                    <a-select-option value="week"> Week </a-select-option>
                    <a-select-option value="month"> Month </a-select-option>
                  </a-select>
                  <a-select
                    v-if="isClickEditCampaings"
                    :disabled="
                      !optionCheckInterval ||
                      (objCampaign.Status != '1' && objCampaign.Status != '2')
                    "
                    v-bind:class="{ active: !optionCheckInterval }"
                    style="width: 183px"
                    v-model="selectedStatus"
                  >
                    <a-select-option value="day"> Day </a-select-option>
                    <a-select-option value="week"> Week </a-select-option>
                    <a-select-option value="month"> Month </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <!-- Importcode -->
            <div
              v-if="
                (isAllowImportSerial && isClickCreateCampaings) ||
                (isClickEditCampaings && objCampaingsSecect.IsImported)
              "
              class="row field-row"
            >
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['burn-import-code'] }}
                </div>
              </div>
              <div
                class="col-md-10 field-column"
                style="position: relative; display: grid"
              >
                <div class="">
                  <div class="div1 txt-left">
                    <input
                      v-if="isClickCreateCampaings"
                      ref="file"
                      type="file"
                      class="d-none"
                      accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      @change="handlekUploadfilse"
                    />
                    <input
                      v-if="isClickEditCampaings"
                      ref="file"
                      type="file"
                      class="d-none"
                      :disabled="objCampaingsSecect.Status == true"
                      accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      @change="handlekUploadfilse"
                    />
                    <div v-show="!dispfile" for="ExcelFile" class="div1">
                      <a-button
                        @click="clickUploadfilse()"
                        class="ant-btn ant-btn-primary-disabled"
                      >
                        {{ lbl['burn-import-code-btn'] }}
                      </a-button>
                    </div>
                    <div class="div2">
                      <span v-show="errtype" style="color: red">
                        {{ lbl['burn-file-type'] }}
                      </span>
                    </div>
                    <div v-show="dispfile">
                      <img
                        class="img-fluid ico-excel"
                        :src="require('@/assets/images/icon-excel@2x.png')"
                        alt=""
                      />
                      <span class="mr-md-2 txt-upload-file">
                        {{ excelFileName }}</span
                      >
                      <img
                        @click="removeFileUpload()"
                        class="img-fluid delete-image"
                        :src="require('@/assets/images/delete-item.png')"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="div3">
                    {{ lbl['burn-column-desc'] }}
                  </div>
                </div>
                <div class="">
                  <div class="div1" style="text-align: left">
                    <div class="">
                      {{ lbl['burn-file-type'] }}
                    </div>
                    <div v-show="errFileUpload" style="color: red">
                      {{ lbl['burn-error-empty-data'] }}
                    </div>
                  </div>
                  <div class="div3">
                    <a
                      class="txt-dwnl-temp"
                      style="color: #2acc52"
                      :href="
                        require('@/assets/file_templates/Import_code_template.xlsx')
                      "
                      download
                    >
                      {{ lbl['burn-download-temp'] }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Period -->
            <div class="row field-row period">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-period'] }}
                </div>
              </div>
              <div
                class="col-md-10 field-column"
                style="position: relative; display: grid"
              >
                <div v-if="isClickCreateCampaings">
                  <div class="div1">
                    <a-date-picker
                      style="width: 183px"
                      :format="dateFormat"
                      v-model="startDatePeriod"
                      :default-value="startDatePeriod"
                      :disabled-date="disabledStartDate"
                      @change="handleChangeStartDate"
                    />
                  </div>
                  <div
                    class="div2"
                    style="height: 100%; display: grid; align-items: center"
                  >
                    {{ lbl['plan-reward-stamp-campaign-manage-period-to'] }}
                  </div>
                  <div class="div2">
                    <a-date-picker
                      style="width: 183px"
                      v-model="endDatePeriod"
                      :format="dateFormat"
                      :default-value="endDatePeriod"
                      :disabled-date="disabledEndDate"
                      @change="handleChangeEndDate"
                    />
                  </div>
                </div>
                <div v-if="isClickEditCampaings">
                  <div class="div1">
                    <a-date-picker
                      style="width: 183px"
                      :disabled="
                        isClickEditCampaings &&
                        objCampaign.Status != '1' &&
                        objCampaign.Status != '2'
                      "
                      :format="dateFormat"
                      v-model="objCampaingsSecect.StartDate"
                      :disabled-date="disabledStartDate"
                      @change="handleChangeStartDate"
                    />
                  </div>
                  <div
                    class="
                      div2
                      show-text-lbl
                      d-flex
                      align-center
                      justify-center
                      h-default
                    "
                  >
                    {{ lbl['plan-reward-stamp-campaign-manage-period-to'] }}
                  </div>
                  <div class="div2">
                    <a-date-picker
                      style="width: 183px"
                      :disabled="
                        isClickEditCampaings &&
                        objCampaign.Status != '1' &&
                        objCampaign.Status != '2'
                      "
                      v-model="objCampaingsSecect.EndDate"
                      :format="dateFormat"
                      :disabled-date="disabledEndDate"
                      @change="handleChangeEndDate"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Status/Hide Campaign -->
            <div class="row field-row quota">
              <!-- Status -->
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-status'] }}
                </div>
              </div>
              <div class="col-md-4 field-column" style="position: relative">
                <a-form-item>
                  <div v-if="isClickCreateCampaings" class="div1">
                    <a-radio-group v-model="campaignStatus">
                      <a-radio :value="true">
                        {{
                          lbl['plan-reward-stamp-campaign-manage-status-active']
                        }}
                      </a-radio>
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-status-inactive'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                  <div v-if="isClickEditCampaings" class="div1">
                    <a-radio-group
                      :disabled="objCampaign.Status == '4'"
                      v-model="objCampaingsSecect.Status"
                    >
                      <a-radio :value="true">
                        {{
                          lbl['plan-reward-stamp-campaign-manage-status-active']
                        }}
                      </a-radio>
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-status-inactive'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-form-item>
              </div>
              <!-- Hide Campaign -->
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['hide-campaign-label'] }}
                </div>
              </div>
              <div class="col-md-4 field-column" style="position: relative">
                <a-form-item>
                  <div v-if="isClickCreateCampaings" class="div1">
                    <a-radio-group v-model="campaingAppsVisibility">
                      <a-radio :value="true">
                        {{ lbl['hide-label'] }}
                      </a-radio>
                      <a-radio :value="false">
                        {{ lbl['unhide-label'] }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                  <div v-if="isClickEditCampaings" class="div1">
                    <div class="div1">
                      <a-radio-group
                        :disabled="
                          objCampaign.Status != '1' && objCampaign.Status != '2'
                        "
                        v-model="objCampaingsSecect.AppsVisibility"
                      >
                        <a-radio :value="true">
                          {{ lbl['hide-label'] }}
                        </a-radio>
                        <a-radio :value="false">
                          {{ lbl['unhide-label'] }}
                        </a-radio>
                      </a-radio-group>
                    </div>
                    <div class="div2 show-text-lbl">
                      <input
                        v-on:focus="$event.target.select()"
                        ref="myinput"
                        readonly
                        :value="consumerUrl + objCampaingsSecect.ID"
                        style="
                          width: 80px;
                          position: absolute;
                          margin-top: 10px;
                          height: 20px;
                        "
                      />
                      <div class="showanimation">
                        <label class="trigger">
                          <input
                            id="checkboxshowanimation"
                            type="checkbox"
                            class="checkboxshow"
                          />
                          <span class="msg">
                            {{ lbl['copied-text'] }}
                          </span>
                        </label>
                      </div>
                      <a-button class="copylink" @click="copylink">
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                </a-form-item>
              </div>
            </div>
            <!-- Redeem -->
            <div v-if="hasUserlevel" class="row field-row interval">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-interval'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <div v-if="isClickCreateCampaings" class="div1">
                  <a-select
                    style="width: 183px"
                    v-model="defaultUserLevelList"
                    @change="changeRedeemLevelList"
                    :disabled="userLevelList.length <= 1"
                  >
                    <a-select-option
                      v-for="(option, keyOption) in userLevelList"
                      :key="keyOption"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div v-if="isClickEditCampaings" class="div1">
                  <a-select
                    :disabled="
                      (objCampaign.Status != '1' &&
                        objCampaign.Status != '2') ||
                      userLevelList.length <= 1
                    "
                    style="width: 183px"
                    v-model="objCampaingsSecect.UserLevel"
                    @change="changeRedeemLevelList"
                  >
                    <a-select-option
                      v-for="(option, keyOption) in userLevelList"
                      :key="keyOption"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
            <!-- Visibility -->
            <div v-if="hasUserlevel" class="row field-row interval">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['campaign-usr-level-visibility'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <div v-if="isClickCreateCampaings" class="div1">
                  <a-select
                    style="width: 183px"
                    v-model="defaultVisibilityLevelList"
                    @change="changeVisibilityLevelList"
                    :disabled="userLevelList.length <= 1"
                  >
                    <a-select-option
                      v-for="(option, keyOption) in userLevelList"
                      :key="keyOption"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div v-if="isClickEditCampaings" class="div1">
                  <a-select
                    :disabled="
                      (objCampaign.Status != '1' &&
                        objCampaign.Status != '2') ||
                      userLevelList.length <= 1
                    "
                    style="width: 183px"
                    v-model="objCampaingsSecect.UserVisibility"
                    @change="changeVisibilityLevelList"
                  >
                    <a-select-option
                      v-for="(option, keyOption) in userLevelList"
                      :key="keyOption"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
            <div
              v-if="objCampaign.Status != '4'"
              class="row field-row btnsubmit"
            >
              <a-form-item>
                <a-button
                  type="primary"
                  html-type="submit"
                  @click="validateFields"
                >
                  {{ lbl['plan-reward-point-burn-manage-save-button'] }}
                </a-button>
              </a-form-item>
            </div>
          </a-form>
        </a-modal>

        <!-- CampaignDetail Duplicate-->
        <a-modal
          id="modal_campaign_detail_duplicate"
          v-model="campaignDetailDuplicate"
          :title="lbl['plan-reward-button-duplicate-reward']"
          :footer="null"
          @cancel="closeModalDuplicateCampaignDetail()"
          centered
          class="modal-campaign-modal"
          :class="[currentLocale]"
        >
          <!-- <a-form :form="formAddCampaign"> -->
          <a-form :form="form">
            <!-- Name -->
            <div class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-name'] }}
                </div>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item :label="lbl['eng-label']">
                  <a-input
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateNameEng',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-name-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                  <a-input
                    type="text"
                    :disabled="true"
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditNameEng',
                      {
                        initialValue: objCampaingsSecect.NameEN,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-name-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item
                  :label="isPhilippines ? lbl['eng-label'] : lbl['th-label']"
                >
                  <a-input
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateNameTh',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-name-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  >
                  </a-input>
                  <a-input
                    type="text"
                    :disabled="true"
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditNameTh',
                      {
                        initialValue: objCampaingsSecect.Name,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-name-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
            </div>
            <!-- Detail -->
            <div class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-detail'] }}
                </div>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item :label="lbl['eng-label']">
                  <a-textarea
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateDetailEng',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-detail-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                  <a-textarea
                    type="text"
                    :disabled="true"
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditDetailEng',
                      {
                        initialValue: objCampaingsSecect.DetailEN,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-detail-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item
                  :label="isPhilippines ? lbl['eng-label'] : lbl['th-label']"
                >
                  <a-textarea
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateDetailTh',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-detail-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  >
                  </a-textarea>
                  <a-textarea
                    type="text"
                    :disabled="true"
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditDetailTh',
                      {
                        initialValue: objCampaingsSecect.Detail,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-detail-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  >
                  </a-textarea>
                </a-form-item>
              </div>
            </div>
            <!-- TermsAndConditions -->
            <div class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative; padding-right: 0px"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-description'] }}
                </div>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item :label="lbl['eng-label']">
                  <a-textarea
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateConditionEN',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-description-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                  <a-textarea
                    type="text"
                    :disabled="true"
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditConditionEN',
                      {
                        initialValue: objCampaingsSecect.ConditionEN,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-description-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
              </div>
              <div class="col-md-5 field-column" style="position: relative">
                <a-form-item
                  :label="isPhilippines ? lbl['eng-label'] : lbl['th-label']"
                >
                  <a-textarea
                    v-if="isClickCreateCampaings"
                    v-decorator="[
                      'CreateConditionTh',
                      {
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-description-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  >
                  </a-textarea>
                  <a-textarea
                    type="text"
                    :disabled="true"
                    v-if="isClickEditCampaings"
                    v-decorator="[
                      'EditConditionTh',
                      {
                        initialValue: objCampaingsSecect.TermsAndConditions,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-description-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  >
                  </a-textarea>
                </a-form-item>
              </div>
            </div>
            <!-- Image -->
            <div class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-image'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <div>
                  <div
                    class="profile-image"
                    @click="clickSelectImageCampaings()"
                  ></div>
                  <input
                    :disabled="true"
                    class="d-none"
                    type="file"
                    ref="inputCampaingsImage"
                    accept="image/png, image/jpeg"
                    @input="onInputImageCampaings($event)"
                  />
                  <div for="ImgFile" style="text-align: left">
                    <div v-if="isClickCreateCampaings">
                      <span @click="clickUploadImage()">
                        <img
                          v-if="!pathImage"
                          :src="require('@/assets/images/blank-photo.png')"
                          alt=""
                          style="width: 100px; cursor: pointer"
                        />
                        <img
                          v-if="pathImage"
                          :src="pathImage"
                          alt=""
                          style="width: 100px; height: 100px; cursor: pointer"
                        />
                      </span>
                    </div>
                    <div v-if="isClickEditCampaings">
                      <span @click="clickUploadImage()">
                        <img
                          v-if="!objCampaingsSecect.ImageUrl"
                          :src="require('@/assets/images/blank-photo.png')"
                          alt=""
                          style="width: 100px; cursor: pointer"
                        />
                        <img
                          v-if="objCampaingsSecect.ImageUrl"
                          :src="objCampaingsSecect.ImageUrl"
                          alt=""
                          style="width: 100px; height: 100px; cursor: pointer"
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    v-show="errimgUpload"
                    style="color: red; text-align: left"
                  >
                    {{
                      lbl[
                        'plan-reward-stamp-campaign-manage-image-required-message'
                      ]
                    }}
                  </div>
                  <div class="">
                    <div class="div1 txt-left">
                      <div class="">
                        {{ this.maximumPhotoSizeUploadInMB }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- PointPerUnit -->
            <div v-if="storeType == 'point'" class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['user-level-number-point'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <a-form-item>
                  <div class="div1">
                    <a-input-number
                      :min="0"
                      v-if="isClickCreateCampaings"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      v-decorator="[
                        'CreatePointPerUnit',
                        {
                          initialValue: pointPerUnit,
                          rules: [
                            {
                              required: true,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-point-use-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                    <a-input-number
                      :min="0"
                      :disabled="true"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      v-if="isClickEditCampaings"
                      v-decorator="[
                        'EditPointPerUnit',
                        {
                          initialValue: objCampaingsSecect.PointPerUnit,
                          rules: [
                            {
                              required: true,
                              message:
                                lbl[
                                  'plan-reward-stamp-campaign-manage-description-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                  </div>
                  <div class="div1">
                    {{ lbl['user-level-number-point'] }}
                  </div>
                </a-form-item>
              </div>
            </div>
            <!-- PricePerUnit -->
            <div v-if="!isPhilippines" class="row field-row">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl-request txt-left">
                  {{ lbl['user-level-price-per-unit'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <a-form-item>
                  <div class="div1">
                    <a-input-number
                      :min="0"
                      v-if="isClickCreateCampaings"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      v-decorator="[
                        'CreatePricePerUnit',
                        {
                          initialValue: pricePerUnit,
                          rules: [
                            {
                              required: true,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-price-per-unit-use-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                    <a-input-number
                      :min="0"
                      :disabled="
                        objCampaign.Status != '1' && objCampaign.Status != '2'
                      "
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      v-if="isClickEditCampaings"
                      v-decorator="[
                        'EditPricePerUnit',
                        {
                          initialValue: objCampaingsSecect.PricePerUnit,
                          rules: [
                            {
                              required: true,
                              message:
                                lbl[
                                  'plan-reward-stamp-campaign-manage-description-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                  </div>
                  <div class="div1">
                    {{ lbl['user-level-number-baht'] }}
                  </div>
                </a-form-item>
                <div class="has-error-price">
                  *{{ lbl['can-edit-later-saving'] }}
                </div>
              </div>
            </div>
            <!-- Quota -->
            <div class="row field-row quota">
              <!-- QuotaTotal -->
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-total-quota'] }}
                </div>
              </div>
              <div class="col-md-4 field-column" style="position: relative">
                <a-form-item>
                  <div class="div1">
                    <a-radio-group
                      :disabled="true"
                      v-model="optionQuotaTotal"
                      @change="changeCreateQuotaTotal"
                    >
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-max-per-person-unlimit'
                          ]
                        }}
                      </a-radio>
                      <a-radio :value="true">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-max-per-person-limit'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-form-item>
                <a-form-item>
                  <div class="div1">
                    <a-input-number
                      :min="1"
                      v-if="isClickCreateCampaings"
                      :disabled="true"
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      v-bind:class="{ active: !optionQuotaTotal }"
                      v-decorator="[
                        'CreateQuotaTotal',
                        {
                          initialValue: quotaTotal,
                          rules: [
                            {
                              required: optionQuotaTotal,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-total-quota-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                    <a-input-number
                      :min="1"
                      v-if="isClickEditCampaings"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      :disabled="true"
                      v-bind:class="{ active: !optionQuotaTotal }"
                      v-decorator="[
                        'EditQuotaTotal',
                        {
                          initialValue: objCampaingsSecect.Quantity,
                          rules: [
                            {
                              required: optionQuotaTotal,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-total-quota-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                  </div>
                </a-form-item>
              </div>
              <!-- QuotaPerson -->
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-max-per-person'] }}
                </div>
              </div>
              <div class="col-md-4 field-column" style="position: relative">
                <a-form-item>
                  <div class="div1">
                    <a-radio-group
                      :disabled="true"
                      v-model="optionQuotaPerson"
                      @change="changeCreateQuotaPerson"
                    >
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-max-per-person-unlimit'
                          ]
                        }}
                      </a-radio>
                      <a-radio :value="true">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-max-per-person-limit'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-form-item>
                <a-form-item>
                  <div class="div1">
                    <a-input-number
                      :min="1"
                      v-if="isClickCreateCampaings"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      :disabled="true"
                      v-bind:class="{ active: !optionQuotaPerson }"
                      v-decorator="[
                        'CreateQuotaPerson',
                        {
                          initialValue: quotaPerson,
                          rules: [
                            {
                              required: optionQuotaPerson,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-total-quota-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                    <a-input-number
                      :min="1"
                      v-if="isClickEditCampaings"
                      :formatter="
                        value =>
                          `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      "
                      :parser="value => value.replace(/[^0-9]/g, '')"
                      :disabled="true"
                      v-bind:class="{ active: !optionQuotaPerson }"
                      v-decorator="[
                        'EditQuotaPerson',
                        {
                          initialValue: objCampaingsSecect.RedeemMostPerPerson,
                          rules: [
                            {
                              required: optionQuotaPerson,
                              message:
                                lbl[
                                  'plan-reward-point-burn-manage-total-quota-required-message'
                                ],
                            },
                          ],
                        },
                      ]"
                    />
                  </div>
                </a-form-item>
              </div>
            </div>
            <!-- Display Campaign -->
            <div class="row field-row quota">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-displaycampaign'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <a-form-item>
                  <div v-if="isClickCreateCampaings" class="div1">
                    <a-radio-group v-model="skipDisplayCampaignPage">
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-show-displaycampaign'
                          ]
                        }}
                      </a-radio>
                      <a-radio :value="true">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-notshow-displaycampaign'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                  <div v-if="isClickEditCampaings" class="div1">
                    <a-radio-group
                      :disabled="true"
                      v-model="objCampaingsSecect.Extra"
                    >
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-show-displaycampaign'
                          ]
                        }}
                      </a-radio>
                      <a-radio :value="true">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-notshow-displaycampaign'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-form-item>
              </div>
            </div>
            <!-- Interval -->
            <div class="row field-row interval">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-point-burn-manage-interval'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <a-form-item>
                  <a-checkbox
                    :disabled="true"
                    :checked="optionCheckInterval"
                    @change="handleCheckInterval"
                  >
                  </a-checkbox>
                </a-form-item>
                <a-form-item>
                  <a-input-number
                    :min="1"
                    v-if="isClickCreateCampaings"
                    :disabled="true"
                    v-bind:class="{ active: !optionCheckInterval }"
                    v-decorator="[
                      'CreateInterval',
                      {
                        initialValue: periodInterval,
                        rules: [
                          {
                            required: optionCheckInterval,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-interval-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                  <a-input-number
                    :min="1"
                    v-if="isClickEditCampaings"
                    :disabled="true"
                    v-bind:class="{ active: !optionCheckInterval }"
                    v-decorator="[
                      'EditInterval',
                      {
                        initialValue:
                          objCampaingsSecect.RedeemMostPerPersonInPeriod,
                        rules: [
                          {
                            required: true,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-description-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                  />
                </a-form-item>
                <a-form-item>
                  <a-select
                    v-if="isClickCreateCampaings"
                    :disabled="true"
                    v-bind:class="{ active: !optionCheckInterval }"
                    v-decorator="[
                      'IntervalPeriod',
                      {
                        rules: [
                          {
                            required: optionCheckInterval,
                            message:
                              lbl[
                                'plan-reward-stamp-campaign-manage-interval-required-message'
                              ],
                          },
                        ],
                      },
                    ]"
                    style="width: 183px"
                  >
                    <a-select-option value="day"> Day </a-select-option>
                    <a-select-option value="week"> Week </a-select-option>
                    <a-select-option value="month"> Month </a-select-option>
                  </a-select>
                  <a-select
                    v-if="isClickEditCampaings"
                    :disabled="true"
                    v-bind:class="{ active: !optionCheckInterval }"
                    style="width: 183px"
                    v-model="selectedStatus"
                  >
                    <a-select-option value="day"> Day </a-select-option>
                    <a-select-option value="week"> Week </a-select-option>
                    <a-select-option value="month"> Month </a-select-option>
                  </a-select>
                </a-form-item>
              </div>
            </div>
            <!-- Importcode -->
            <div
              v-if="
                (isAllowImportSerial && isClickCreateCampaings) ||
                (isClickEditCampaings && objCampaingsSecect.IsImported)
              "
              class="row field-row"
            >
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['burn-import-code'] }}
                </div>
              </div>
              <div
                class="col-md-10 field-column"
                style="position: relative; display: grid"
              >
                <div class="">
                  <div class="div1 txt-left">
                    <input
                      v-if="isClickCreateCampaings"
                      ref="file"
                      type="file"
                      class="d-none"
                      accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      @change="handlekUploadfilse"
                    />
                    <input
                      v-if="isClickEditCampaings"
                      ref="file"
                      type="file"
                      class="d-none"
                      :disabled="true"
                      accept="text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      @change="handlekUploadfilse"
                    />
                    <div v-show="!dispfile" for="ExcelFile" class="div1">
                      <a-button
                        @click="clickUploadfilse()"
                        class="ant-btn ant-btn-primary-disabled"
                      >
                        {{ lbl['burn-import-code-btn'] }}
                      </a-button>
                    </div>
                    <div class="div2">
                      <span v-show="errtype" style="color: red">
                        {{ lbl['burn-file-type'] }}
                      </span>
                    </div>
                    <div v-show="dispfile">
                      <img
                        class="img-fluid ico-excel"
                        :src="require('@/assets/images/icon-excel@2x.png')"
                        alt=""
                      />
                      <span class="mr-md-2 txt-upload-file">
                        {{ excelFileName }}</span
                      >
                      <img
                        @click="removeFileUpload()"
                        class="img-fluid delete-image"
                        :src="require('@/assets/images/delete-item.png')"
                        alt=""
                      />
                    </div>
                  </div>
                  <div class="div3">
                    {{ lbl['burn-column-desc'] }}
                  </div>
                </div>
                <div class="">
                  <div class="div1" style="text-align: left">
                    <div class="">
                      {{ lbl['burn-file-type'] }}
                    </div>
                    <div v-show="errFileUpload" style="color: red">
                      {{ lbl['burn-error-empty-data'] }}
                    </div>
                  </div>
                  <div class="div3">
                    <a
                      class="txt-dwnl-temp"
                      style="color: #2acc52"
                      :href="
                        require('@/assets/file_templates/Import_code_template.xlsx')
                      "
                      download
                    >
                      {{ lbl['burn-download-temp'] }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- Period -->
            <div class="row field-row period">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-period'] }}
                </div>
              </div>
              <div
                class="col-md-10 field-column"
                style="position: relative; display: grid"
              >
                <div v-if="isClickCreateCampaings">
                  <div class="div1">
                    <a-date-picker
                      style="width: 183px"
                      :format="dateFormat"
                      v-model="startDatePeriod"
                      :default-value="startDatePeriod"
                      :disabled-date="disabledStartDate"
                      @change="handleChangeStartDate"
                    />
                  </div>
                  <div
                    class="div2"
                    style="height: 100%; display: grid; align-items: center"
                  >
                    {{ lbl['plan-reward-stamp-campaign-manage-period-to'] }}
                  </div>
                  <div class="div2">
                    <a-date-picker
                      style="width: 183px"
                      v-model="endDatePeriod"
                      :format="dateFormat"
                      :default-value="endDatePeriod"
                      :disabled-date="disabledEndDate"
                      @change="handleChangeEndDate"
                    />
                  </div>
                </div>
                <div v-if="isClickEditCampaings">
                  <div class="div1">
                    <a-date-picker
                      style="width: 183px"
                      :format="dateFormat"
                      v-model="objCampaingsSecect.StartDate"
                      :disabled-date="disabledStartDate"
                      @change="handleChangeStartDate"
                    />
                  </div>
                  <div
                    class="
                      div2
                      show-text-lbl
                      d-flex
                      align-center
                      justify-center
                      h-default
                    "
                  >
                    {{ lbl['plan-reward-stamp-campaign-manage-period-to'] }}
                  </div>
                  <div class="div2">
                    <a-date-picker
                      style="width: 183px"
                      v-model="objCampaingsSecect.EndDate"
                      :format="dateFormat"
                      :disabled-date="disabledEndDate"
                      @change="handleChangeEndDate"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Status/Hide Campaign -->
            <div class="row field-row quota">
              <!-- Status -->
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-status'] }}
                </div>
              </div>
              <div class="col-md-4 field-column" style="position: relative">
                <a-form-item>
                  <div v-if="isClickCreateCampaings" class="div1">
                    <a-radio-group v-model="campaignStatus">
                      <a-radio :value="true">
                        {{
                          lbl['plan-reward-stamp-campaign-manage-status-active']
                        }}
                      </a-radio>
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-status-inactive'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                  <div v-if="isClickEditCampaings" class="div1">
                    <a-radio-group v-model="objCampaingsSecect.Status">
                      <a-radio :value="true">
                        {{
                          lbl['plan-reward-stamp-campaign-manage-status-active']
                        }}
                      </a-radio>
                      <a-radio :value="false">
                        {{
                          lbl[
                            'plan-reward-stamp-campaign-manage-status-inactive'
                          ]
                        }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                </a-form-item>
              </div>
              <!-- Hide Campaign -->
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['hide-campaign-label'] }}
                </div>
              </div>
              <div class="col-md-4 field-column" style="position: relative">
                <a-form-item>
                  <div v-if="isClickCreateCampaings" class="div1">
                    <a-radio-group v-model="campaingAppsVisibility">
                      <a-radio :value="true">
                        {{ lbl['hide-label'] }}
                      </a-radio>
                      <a-radio :value="false">
                        {{ lbl['unhide-label'] }}
                      </a-radio>
                    </a-radio-group>
                  </div>
                  <div v-if="isClickEditCampaings" class="div1">
                    <div class="div1">
                      <a-radio-group
                        v-model="objCampaingsSecect.AppsVisibility"
                      >
                        <a-radio :value="true">
                          {{ lbl['hide-label'] }}
                        </a-radio>
                        <a-radio :value="false">
                          {{ lbl['unhide-label'] }}
                        </a-radio>
                      </a-radio-group>
                    </div>
                    <div class="div2 show-text-lbl">
                      <input
                        v-on:focus="$event.target.select()"
                        ref="myinput"
                        readonly
                        :value="consumerUrl + objCampaingsSecect.ID"
                        style="
                          width: 80px;
                          position: absolute;
                          margin-top: 10px;
                          height: 20px;
                        "
                      />
                      <div class="showanimation">
                        <label class="trigger">
                          <input
                            id="checkboxshowanimation"
                            type="checkbox"
                            class="checkboxshow"
                          />
                          <span class="msg">
                            {{ lbl['copied-text'] }}
                          </span>
                        </label>
                      </div>
                      <a-button class="copylink" @click="copylink">
                        {{ lbl['copy-link'] }}
                      </a-button>
                    </div>
                  </div>
                </a-form-item>
              </div>
            </div>
            <!-- Redeem -->
            <div v-if="hasUserlevel" class="row field-row interval">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['plan-reward-stamp-campaign-manage-interval'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <div v-if="isClickCreateCampaings" class="div1">
                  <a-select
                    style="width: 183px"
                    v-model="defaultUserLevelList"
                    @change="changeRedeemLevelList"
                    :disabled="true"
                  >
                    <a-select-option
                      v-for="(option, keyOption) in userLevelList"
                      :key="keyOption"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div v-if="isClickEditCampaings" class="div1">
                  <a-select
                    :disabled="true"
                    style="width: 183px"
                    v-model="objCampaingsSecect.UserLevel"
                    @change="changeRedeemLevelList"
                  >
                    <a-select-option
                      v-for="(option, keyOption) in userLevelList"
                      :key="keyOption"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
            <!-- Visibility -->
            <div v-if="hasUserlevel" class="row field-row interval">
              <div
                class="col col-md-2 field-column field-label col-form-label"
                style="position: relative"
              >
                <div class="show-text-lbl txt-left">
                  {{ lbl['campaign-usr-level-visibility'] }}
                </div>
              </div>
              <div class="col-md-10 field-column" style="position: relative">
                <div v-if="isClickCreateCampaings" class="div1">
                  <a-select
                    style="width: 183px"
                    v-model="defaultVisibilityLevelList"
                    @change="changeVisibilityLevelList"
                    :disabled="true"
                  >
                    <a-select-option
                      v-for="(option, keyOption) in userLevelList"
                      :key="keyOption"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </div>
                <div v-if="isClickEditCampaings" class="div1">
                  <a-select
                    :disabled="true"
                    style="width: 183px"
                    v-model="objCampaingsSecect.UserVisibility"
                    @change="changeVisibilityLevelList"
                  >
                    <a-select-option
                      v-for="(option, keyOption) in userLevelList"
                      :key="keyOption"
                      :value="option.value"
                    >
                      {{ option.name }}
                    </a-select-option>
                  </a-select>
                </div>
              </div>
            </div>
            <div class="row field-row btnsubmit">
              <a-form-item>
                <a-button
                  type="primary"
                  html-type="submit"
                  @click="validateFields"
                >
                  {{ lbl['plan-reward-point-burn-manage-save-button'] }}
                </a-button>
              </a-form-item>
            </div>
          </a-form>
        </a-modal>

        <ConfirmModal
          :isOpenModal="confirmModalSetting.isOpenModal"
          :modalType="confirmModalSetting.modalType"
          :title="confirmModalSetting.title"
          :details="confirmModalSetting.details"
          :backto="confirmModalSetting.backto"
          :onConfirm="confirmModalSetting.onConfirm"
          :onCancel="confirmModalSetting.onCancel"
        />
        <AlertModal
          :isOpenModal="alertModalSetting.isOpenModal"
          :modalType="alertModalSetting.modalType"
          :title="alertModalSetting.title"
          :details="alertModalSetting.details"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Account from '@/helper/AccountHelper.js'
import Vue from 'vue'
import Mixin from '@/mixin/Mixin'
import Cache from '@/helper/Cache.js'
import fileReader from '@/helper/fileReader'
import BzbsProfile from '@/core/Account/service/BzbsProfile'
import BzbsPlanReward from '@/core/PlanReward/service/BzbsPlanReward'
import BzbsImport from '@/core/Import/service/BzbsImport'
import CheckBox from './components/CheckBox.vue'
import Locale from '@/helper/locale.js'
import moment from 'moment'
import XLSX from 'xlsx'
import draggable from 'vuedraggable'
import Header from '@/components/Layout/Header'
import ExportExcel from '@/module/components/dashboard/botton/ExportExcel'
import ConfirmModal from '@/components/Modal/Component/confirmmodal2'
import AlertModal from '@/components/Modal/Component/alertmodal2'
import BzbsSettingsLine from '@/core/Settings/Line/BzbsSettingsLine'

const columnsSerialCodeList = [
  {
    title: 'Number',
    dataIndex: 'Number',
    key: 'view-code-number',
    width: '80px',
    align: 'center',
  },
  {
    title: 'Code',
    dataIndex: 'Code',
    key: 'view-code-code',
    width: '150px',
    align: 'center',
  },
  {
    title: 'UserId',
    dataIndex: 'UserId',
    key: 'view-code-user-id',
    align: 'center',
  },
  {
    title: 'Name',
    dataIndex: 'FullName',
    key: 'plan-reward-point-burn-manage-name',
    align: 'center',
  },
  {
    title: 'Contact Number',
    dataIndex: 'ContactNumber',
    key: 'company-setup-branch-contact-number-placeholder',
    align: 'center',
  },
  {
    title: 'UsedOn',
    dataIndex: 'UsedOn',
    key: 'view-code-user-on',
    width: '300px',
    align: 'center',
    scopedSlots: {
      customRender: 'date',
    },
  },
]
export default {
  name: 'burnListNew',
  components: {
    draggable,
    Header,
    CheckBox,
    ExportExcel,
    ConfirmModal,
    AlertModal,
  },
  mixins: [Mixin],
  data: function () {
    return {
      crmPackage: {},
      currentLocale: Locale.getLocaleShort(),
      isPhilippines: false,
      showFilterMain: false,
      subFilter: 'status',
      filter: {
        status: {
          value: null,
          input: ['Active', 'Inactive'],
          label: 'Status',
          type: 'checkbox',
        },
      },
      isLoading: false,
      hasUserlevel: false,
      isAllowImportSerial: false,
      storeType: '',
      campaignMode: '',
      objBurnCampaings: [],
      languageLocale: '',
      objSerialCodeList: [],
      listsequence: [],
      visibleSerialCodeList: false,
      columnsSerialCodeList,
      pageSizeSerial: 20,
      pageSizeCampaings: 20,
      pageSizeOptions: ['20', '50', '100'],
      defaultPageSize: 20,
      currentSerial: 1,
      currentCampaings: 1,
      totalSerial: null,
      totalCampaings: null,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      campaignDetail: false,
      isClickCreateCampaings: false,
      isClickEditCampaings: false,
      objCampaingsSecect: [],
      optionQuotaPerson: false,
      optionQuotaTotal: false,
      quotaPerson: 0,
      quotaTotal: 0,
      periodInterval: 0,
      optionCheckInterval: false,
      selectedStatus: '',
      minDate: moment(),
      dateFormat: 'DD MMM YYYY',
      dateFormatSave: 'YYYY-MM-DD',
      startDatePeriod: moment(),
      endDatePeriod: moment(),
      campaignStatus: true,
      skipDisplayCampaignPage: false,
      campaingAppsVisibility: false,
      consumerUrl: '',
      userLevel: [],
      userLevelList: [],
      defaultUserLevelList: 0,
      defaultVisibilityLevelList: 0,
      objCampaingsAdd: [],
      dispfile: false,
      excelFileName: '',
      errFileUpload: false,
      errtype: false,
      pathImage: '',
      errimgUpload: false,
      maximumPhotoSizeUploadInMB: '',
      pointPerUnit: 0,
      pricePerUnit: 0,
      objCampaign: [],
      filteredStatus: [],
      statusFiltered: [],
      searchName: [],
      searchText: '',
      newselectedKeys: 'new',
      oldselectedKeys: 'old',
      searchInput: null,
      appId: null,
      liffId: null,
      liffLanding: null,
      listCampaings: [
        {
          title: '',
          key: 'sequence',
          width: '50px',
          scopedSlots: {
            customRender: 'sequence',
          },
          align: 'center',
        },
        {
          title: 'Status',
          width: Locale.getLocaleCode() == 'th' ? '120px' : '90px',
          align: 'center',
          dataIndex: 'StatusActive',
          scopedSlots: {
            customRender: 'status',
          },
          key: 'plan-reward-point-earn-manage-status',
          lblActive: 'plan-reward-point-earn-manage-status-active',
          lblInActive: 'plan-reward-point-earn-manage-status-inactive',
        },
        {
          title: 'ID',
          dataIndex: 'ID',
          key: 'plan-reward-point-earn-list-id',
          width: '80px',
          align: 'left',
        },
        {
          title: 'Rewards',
          width: '280px',
          key: 'plan-reward-point-burn-list-name',
          align: 'left',
          scopedSlots: {
            customRender: 'campaign',
          },
        },
        {
          title: 'Period',
          key: 'plan-reward-point-burn-list-period',
          width: '200px',
          align: 'center',
          scopedSlots: {
            customRender: 'date',
          },
        },
        {
          title: 'PointtoUse',
          dataIndex: 'PointPerUnit',
          key: 'plan-reward-point-burn-list-point-use',
          width: '120px',
          align: 'center',
        },
        {
          title: 'ViewCode	',
          key: 'view-code-col',
          width: '120px',
          align: 'center',
          scopedSlots: {
            customRender: 'viewcode',
          },
        },
        {
          title: 'TotalRedeem	',
          dataIndex: 'ItemCountSold',
          key: 'burn-list-itm-count-sold',
          width: '120px',
          align: 'center',
        },
        {
          title: '',
          dataIndex: 'ID',
          align: 'left',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ],
      quantity: 0,
      campaignDetailDuplicate: false,
      isClickDuplicateCampaings: false,
      confirmModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        backto: '',
        onConfirm: null,
        onCancel: null,
        isOpenModal: false,
      },
      alertModalSetting: {
        title: '',
        detail: '',
        modalType: '',
        isOpenModal: false,
      },
      allFilter: {},
      loginType: 'line',
      count_active: 0,
    }
  },
  computed: {
    isLimit() {
      if (this.crmPackage && this.crmPackage.IsFreePackage) {
        if (this.count_active < 2) {
          return false
        } else {
          return true
        }
      } else {
        return false
      }
    },
    checkAppId() {
      if (this.loginType == 'line') {
        if (this.appId && (this.liffLanding || this.liffId)) {
          return false
        } else {
          return true
        }
      } else {
        if (this.appId) {
          return false
        } else {
          return true
        }
      }
    },
  },
  created() {
    Account.bzbsAnalyticTracking(
      'rewards_page',
      'reward_list',
      'view_reward_list',
      'on view',
    )
    this.handleFooter(true)
    this.handleLoading(true)
    this.crmPackage = Account.getCachePackage()
    if (Vue.bzbsConfig.businessRule.webFor.PH) {
      this.isPhilippines = true
    }
    this.init()
    this.setLocaleActive()
    if (this.$route.query.backTo) {
      this.handleCreateCampaings()
    }
  },
  methods: {
    moment,
    config: function () {
      return Vue.bzbsConfig
    },
    setLocaleActive() {
      _.forEach(this.listCampaings, column => {
        if (column.dataIndex === 'StatusActive') {
          if (column.filters) {
            column.filters.push(
              {
                text: this.lbl[column.lblActive],
                value: this.lbl[column.lblActive],
              },
              {
                text: this.lbl[column.lblInActive],
                value: this.lbl[column.lblInActive],
              },
            )
          }
        }
      })
    },
    handleSearch(selectedKeys, confirm) {
      this.newselectedKeys = this.searchName[0]
      if (this.newselectedKeys == this.oldselectedKeys) {
        return
      }
      this.oldselectedKeys = this.searchName[0]
      confirm()
    },
    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
      this.searchName[0] = ''
      this.filteredStatus = []
      this.newselectedKeys = 'new'
      this.newselectedKeys = 'old'
    },
    confirmModalAction(
      title,
      details,
      modalType,
      backto,
      isOpenModal,
      onConfirm,
      onCancel,
    ) {
      this.confirmModalSetting.title = title
      this.confirmModalSetting.details = details
      this.confirmModalSetting.modalType = modalType
      this.confirmModalSetting.isOpenModal = isOpenModal
      this.confirmModalSetting.backto = backto
      this.confirmModalSetting.onConfirm = onConfirm
      this.confirmModalSetting.onCancel = onCancel
    },
    alertModalAction(title, details, modalType, isOpenModal) {
      this.alertModalSetting.title = title
      this.alertModalSetting.details = details
      this.alertModalSetting.modalType = modalType
      this.alertModalSetting.isOpenModal = isOpenModal
      if (modalType == 'success' && isOpenModal) {
        setTimeout(() => {
          this.onsuccessCreate()
        }, 1000)
      } else {
        if (isOpenModal)
          setTimeout(() => {
            location.reload()
          }, 1000)
      }
    },
    onsuccessCreate() {
      this.editCampaign = false
      if (this.$route.query.backTo) {
        this.$router.push({
          name: 'InactiveUser',
          query: {
            openreward: 'open',
            mode: 'create',
            id: this.$route.query.backTo,
          },
        })
      } else {
        location.reload()
      }
    },
    init() {
      this.languageLocale = Locale.getLocaleCode()
      this.setLocale()

      this.statusFiltered.push(
        this.lbl['plan-reward-point-earn-manage-status-active'],
        this.lbl['plan-reward-point-earn-manage-status-inactive'],
      )
      if (Cache.get('CRMUser').hasUserlevel == 'true') {
        this.hasUserlevel = true
      } else {
        this.hasUserlevel = false
      }

      this.getCRMPlusProfile().then(result => {
        this.appId = result.AppId
        this.liffId = result.LiffId
        this.liffLanding = result.LiffLanding

        this.loginType = result.LoginType
          ? result.LoginType.toLowerCase()
          : null
        if (this.loginType.toLowerCase() != 'email') {
          this.getRichmenu()
        }

        this.storeType = result.StoreType
        this.campaignMode = result.StoreType
        if (this.campaignMode == 'point') {
          this.campaignMode = this.lbl['import-mode-point']
        } else {
          this.campaignMode = this.lbl['import-mode-stamp']
        }

        this.isAllowImportSerial = result.AllowImportSerial

        this.sizeImage = fileReader.sizeImage()
        var maximumConfig = fileReader.MaximumPhotoSizeUploadInMB()
        var lblimportimgerror =
          this.lbl[
            'plan-reward-stamp-campaign-manage-image-invalid-extension-message'
          ]
        var lblmaximumConfig = this.lbl['image-recommended-message-setting']

        this.maximumPhotoSizeUploadInMB = lblmaximumConfig.replace(
          '{0}',
          maximumConfig,
        )
        this.imPortImgError = lblimportimgerror.replace('{0}', maximumConfig)

        this.levelModeCRMPlusProfile = result.levelmode
        this.getcrmPlusUserLevel(this.levelModeCRMPlusProfile).then(res => {
          if (res) {
            if (res.length == 0) {
              this.userLevelList.push(
                this.lbl['miscellaneous-please-select-option'],
              )
            }
            if (res.length > 0) {
              this.userLevelList = []
              _.forEach(res, item => {
                this.userLevel = this.userLevel + item.UserLevelName + ' & '
              })
              this.userLevel = this.userLevel.substring(
                0,
                this.userLevel.length - 2,
              )
              this.userLevelList.push({ value: 0, name: this.userLevel })
              for (let i = 0; i < res.length; i++) {
                this.userLevelList.push({
                  value: res[i].UserLevel,
                  name: res[i].UserLevelName,
                })
              }
            }
          }
          this.getCRMPlusBurnCampaings(null).then(result => {
            this.objBurnCampaings = result
            this.totalCampaings = result.length
            for (let i = 0; i < this.objBurnCampaings.length; i++) {
              this.listsequence.push([
                {
                  id: this.objBurnCampaings[i].ID,
                },
              ])
              if (this.objBurnCampaings[i].Status == 3) {
                this.objBurnCampaings[i].StatusActive =
                  this.lbl['plan-reward-point-earn-manage-status-active']
              } else {
                this.objBurnCampaings[i].StatusActive =
                  this.lbl['plan-reward-point-earn-manage-status-inactive']
              }
            }
            if (this.objBurnCampaings.length) {
              var activeCount = this.objBurnCampaings.filter(val => {
                return val.Status == 3
              })
              this.count_active = activeCount.length ? activeCount.length : 0
            }
          })
        })
      })
    },
    getRichmenu() {
      return new Promise(resolve => {
        BzbsSettingsLine.getConnectLine().then(resLine => {
          if (resLine) {
            if (resLine.data) {
              if (resLine.data.liffLanding) {
                var liff = resLine.data.liffLanding
                var replace_liff = liff.replace(
                  'line://app/',
                  'https://liff.line.me/',
                )
                this.consumerUrl = replace_liff + '?target_page=detail/'
              }
            }
            resolve(resLine)
          } else {
            resolve(true)
          }
        })
      })
    },
    setLocale() {
      setTimeout(() => {
        _.forEach(this.listCampaings, column => {
          column.title = this.lbl[column.key]
        })
        _.forEach(this.columnsSerialCodeList, column => {
          column.title = this.lbl[column.key]
        })
      }, 500)
    },
    onEnd(e, oldVal) {
      console.log('onEnd : ', e, oldVal)
      var old_Val =
        oldVal + (this.currentCampaings - 1) * this.pageSizeCampaings
      var clone = _.cloneDeep(this.objBurnCampaings[old_Val])
      this.objBurnCampaings.splice(old_Val, 1)
      this.objBurnCampaings.splice(
        parseInt(e.to.id) +
          (this.currentCampaings - 1) * this.pageSizeCampaings,
        0,
        clone,
      )
      this.$nextTick(() => {
        this.listsequence = []
        var campaign = []
        _.forEach(this.objBurnCampaings, item => {
          this.listsequence.push([
            {
              id: item.ID,
            },
          ])
          campaign.push(item.ID)
        })
        this.postBurnCampaingsSequence(campaign)
      })
    },
    postBurnCampaingsSequence(obj) {
      return new Promise((resolve, reject) => {
        BzbsPlanReward.postBurnCampaingsSequence(obj)
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    onEnterInput(event) {
      console.log('onEnterInput : ', event)
      Account.bzbsAnalyticTracking(
        'rewards_page',
        'reward_list',
        'click_reward_search',
        'on click',
      )
      this.searchFilter()
    },
    onChangeInput(event) {
      if (!event.target.value) {
        this.searchFilter()
      }
    },
    toggle(data) {
      this[data] = !this[data]
      Account.bzbsAnalyticTracking(
        'rewards_page',
        'reward_list',
        'click_reward_filter',
        'on click',
      )
    },
    changeSubFilter(type) {
      this.subFilter = type
    },
    selectFilter(item) {
      if (
        (this.filter[this.subFilter].value === 'Active' && item === 'Active') ||
        (this.filter[this.subFilter].value === 'Inactive' &&
          item === 'Inactive')
      ) {
        this.filter[this.subFilter].value = null
      } else {
        this.filter[this.subFilter].value = item
      }
    },
    resetFilter() {
      this.filter.status.value = ''
      this.searchFilter()
    },
    removeFilter(type) {
      Object.keys(this.filter).forEach(item => {
        if (type === item) this.filter[item].value = ''
      })
      this.searchFilter()
    },
    searchFilter() {
      this.showFilterMain = false
      this.allFilter = Object.keys(this.filter).reduce((acc, cur) => {
        if (!this.filter[cur].value) return acc
        return (acc = { ...acc, [cur]: this.filter[cur].value })
      }, {})

      let active = null
      let search = this.searchInput ? this.searchInput.toLowerCase() : null
      if (this.filter.status.value === 'Active') active = true
      else if (this.filter.status.value === 'Inactive') active = false

      this.getCRMPlusBurnCampaings(active).then(result => {
        if (search) {
          this.objBurnCampaings = _.filter(result, item => {
            let locale = Locale.getLocaleCode()
            if (locale != 'th') locale = 'en'

            var campaignName = locale == 'th' ? item.Name : item.NameEN
            if (
              campaignName.toLowerCase().includes(search) ||
              item.ID == search
            )
              return item
          })
          this.totalCampaings = this.objBurnCampaings.length

          for (let i = 0; i < this.objBurnCampaings.length; i++) {
            this.listsequence.push([
              {
                id: this.objBurnCampaings[i].ID,
              },
            ])
            if (this.objBurnCampaings[i].Status == 3) {
              this.objBurnCampaings[i].StatusActive =
                this.lbl['plan-reward-point-earn-manage-status-active']
            } else {
              this.objBurnCampaings[i].StatusActive =
                this.lbl['plan-reward-point-earn-manage-status-inactive']
            }
          }
        } else {
          this.objBurnCampaings = result
          this.totalCampaings = result.length
          for (let i = 0; i < this.objBurnCampaings.length; i++) {
            this.listsequence.push([
              {
                id: this.objBurnCampaings[i].ID,
              },
            ])
            if (this.objBurnCampaings[i].Status == 3) {
              this.objBurnCampaings[i].StatusActive =
                this.lbl['plan-reward-point-earn-manage-status-active']
            } else {
              this.objBurnCampaings[i].StatusActive =
                this.lbl['plan-reward-point-earn-manage-status-inactive']
            }
          }
        }
      })
    },

    /*#region call API*/
    getCRMPlusProfile() {
      this.handleLoading(true)
      return new Promise((resolve, reject) => {
        BzbsProfile.getCRMPlusProfile()
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            reject(error)
          })
      })
    },
    getCRMPlusBurnCampaings(active) {
      this.handleLoading(true)
      return new Promise((resolve, reject) => {
        BzbsPlanReward.getCRMPlusBurnCampaings(active)
          .then(res => {
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            reject(error)
          })
      })
    },
    getSerialCodeList(record) {
      this.handleLoading(true)
      this.paramgetSerialCodeList = []
      this.paramgetSerialCodeList.campaignID = record.ID
      this.paramgetSerialCodeList.skip = 0
      return new Promise((resolve, reject) => {
        BzbsPlanReward.getSerialCodeList(this.paramgetSerialCodeList)
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            reject(error)
          })
      })
    },
    getcrmPlusUserLevel(mode) {
      return new Promise((resolve, reject) => {
        BzbsImport.getcrmPlusUserLevel(mode)
          .then(res => {
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            reject(error)
          })
      })
    },
    postCRMPlusBurnCampaingsAdd() {
      this.handleLoading(true)
      BzbsPlanReward.postCRMPlusBurnCampaingsAdd(this.objCampaingsSecectForSave)
        .then(res => {
          this.handleLoading(false)
          if (res.status == '200') {
            this.alertModalAction(
              this.lbl['alert-box-success-header'],
              this.lbl['alert-box-success-waiting-15-minutes-message'],
              'success',
              true,
            )
          }
        })
        .catch(error => {
          this.handleLoading(false)
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            error.response.data.error.message,
            'error',
            true,
          )
        })
    },
    postCRMPlusBurnCampaingsEdit() {
      this.handleLoading(true)
      BzbsPlanReward.postCRMPlusBurnCampaingsEdit(
        this.objCampaingsSecectForSave,
      )
        .then(res => {
          this.handleLoading(false)
          if (res.status == '200') {
            this.alertModalAction(
              this.lbl['alert-box-success-header'],
              this.lbl[
                'plan-reward-stamp-campaign-manage-edit-success-message'
              ],
              'success',
              true,
            )
          }
        })
        .catch(error => {
          this.handleLoading(false)
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            error.response.data.error.message,
            'error',
            true,
          )
          console.log('postCompanyUser error', error)
        })
    },
    postCRMPlusBurnCampaingsDuplicate() {
      this.handleLoading(true)
      BzbsPlanReward.postCRMPlusBurnCampaingsDuplicate(
        this.objCampaingsSecectForSave,
      )
        .then(res => {
          this.handleLoading(false)
          if (res.status == '200') {
            this.alertModalAction(
              this.lbl['alert-box-success-header'],
              this.lbl[
                'plan-reward-stamp-campaign-manage-duplicate-success-message'
              ],
              'success',
              true,
            )
          }
        })
        .catch(error => {
          this.handleLoading(false)
          console.log('postCompanyUser error', error)
          this.alertModalAction(
            this.lbl['alert-box-error-header'],
            error.response.data.error.message,
            'error',
            true,
          )
        })
    },
    /*#endregion call API*/

    /*#region Action*/
    handleSearchSerialCodeList(record) {
      Account.bzbsAnalyticTracking(
        'rewards_page',
        'reward_list',
        'click_reward_view_code_' + record.Name,
        'on click',
      )
      this.handleLoading(true)
      this.resetCondition()
      this.visibleSerialCodeList = true
      this.getSerialCodeList(record).then(result => {
        this.totalSerial = result.Count
        this.objSerialCodeList = result.Serials
        this.handleLoading(false)
      })
    },
    clickgoto(page) {
      window.location.href = window.location.origin + page
    },
    onChangePageSerial(page) {
      this.currentSerial = page
    },
    onChangePageCampaings(page) {
      this.currentCampaings = page
    },
    onShowSizeChange(page, range) {
      this.pageSizeCampaings = range
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.isClickCreateCampaings && this.pathImage == '') {
        this.errimgUpload = true
      }
      if (this.isClickEditCampaings && this.objCampaingsSecect.ImageUrl == '') {
        this.errimgUpload = true
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    },
    handleCreateCampaings() {
      this.quantity = 0
      this.handleLoading(true)
      this.form.resetFields()
      this.objCampaingsAdd = []
      this.objCampaign = []
      this.objExtralist = {}
      this.resetCondition()
      this.clearerror()
      this.isClickCreateCampaings = true
      Account.bzbsAnalyticTracking(
        'rewards_page',
        'reward_list',
        'click_reward_create',
        'on click',
      )
      if (this.hasUserlevel && this.userLevelList.length > 0) {
        this.defaultUserLevelList = 0
        this.defaultVisibilityLevelList = 0
      }
      this.campaignDetail = true
      if (this.isClickEditCampaings) {
        Account.bzbsAnalyticTracking(
          'rewards_page',
          'reward_edit',
          'view_edit_reward',
          'on view',
        )
      } else {
        Account.bzbsAnalyticTracking(
          'rewards_page',
          'reward_create',
          'view_create_reward',
          'on view',
        )
      }
      this.handleLoading(false)
    },
    handleEditCampaings(record) {
      Account.bzbsAnalyticTracking(
        'rewards_page',
        'reward_list',
        'click_reward_edit_' + record.Name,
        'on click',
      )
      this.clearerror()
      this.objCampaign = record
      this.objExtralist = {}
      this.handleLoading(true)
      setTimeout(() => {
        if (this.visibleSerialCodeList) {
          this.handleLoading(false)
          return
        }
        this.form.resetFields()
        this.resetCondition()
        if (record.Extra) {
          var objExtra = JSON.parse(record.Extra)
          this.objExtralist = objExtra
          if (objExtra) {
            objExtra = objExtra.IsSkipDetailPage
              ? objExtra.IsSkipDetailPage
              : false
          }
        }

        this.quantity = record.Quantity
        let locale = Locale.getLocaleCode()
        if (locale != 'th') locale = 'en'
        moment.locale(locale)

        this.objCampaingsSecect = {
          ID: record.ID,
          Name: record.Name,
          NameEN: record.NameEN,
          Detail: record.Detail,
          DetailEN: record.DetailEN,
          TermsAndConditions: record.TermsAndConditions,
          ConditionEN: record.ConditionEN,
          PointPerUnit: record.PointPerUnit,
          PricePerUnit: record.PricePerUnit,
          Quantity: record.Quantity,
          RedeemMostPerPerson: record.RedeemMostPerPerson,
          RedeemMostPerPersonInPeriod: record.RedeemMostPerPersonInPeriod,
          StartDate: moment.unix(record.StartDate).utc(),
          EndDate: moment.unix(record.EndDate).utc(),
          Status: record.Status == 3 || record.Status == 5 ? true : false,
          AppsVisibility: record.AppsVisibility ? false : true,
          UserLevel: record.UserLevel ? record.UserLevel : 0,
          UserVisibility: record.UserVisibility ? record.UserVisibility : 0,
          ImageUrl: record.ImageUrl + `?v=` + new Date().getTime(),
          Extra: objExtra ? objExtra : false,
          IsImported: record.IsImported,
        }
        console.log('objCampaingsSecect', this.objCampaingsSecect)

        if (record.Quantity > 0 && record.Quantity < 1000000) {
          this.optionQuotaTotal = true
        }
        if (record.RedeemMostPerPerson > 0) {
          this.optionQuotaPerson = true
        }
        if (record.RedeemMostPerPersonInPeriod > 0) {
          this.optionCheckInterval = true
          if (
            record.TimeRounding != 'day' &&
            record.TimeRounding != 'week' &&
            record.TimeRounding != 'month'
          ) {
            this.selectedStatus = 'day'
          } else {
            this.selectedStatus = record.TimeRounding
          }
        }
        if (this.userLevelList.length <= 1) {
          this.objCampaingsSecect.UserVisibility = 0
          this.objCampaingsSecect.UserLevel = 0
        }
        this.$nextTick(() => {
          this.isClickEditCampaings = true
          this.isClickDuplicateCampaings = false
          this.campaignDetail = true
          this.handleLoading(false)
        })
      }, 1000)
    },
    handleDuplicateCampaings(record) {
      Account.bzbsAnalyticTracking(
        'rewards_page',
        'reward_list',
        'click_reward_duplicate_' + record.Name,
        'on click',
      )
      this.clearerror()
      this.objCampaign = record
      this.objExtralist = {}
      this.handleLoading(true)
      setTimeout(() => {
        if (this.visibleSerialCodeList) {
          this.handleLoading(false)
          return
        }
        this.form.resetFields()
        this.resetCondition()
        if (record.Extra) {
          var objExtra = JSON.parse(record.Extra)
          this.objExtralist = objExtra
          if (objExtra) {
            objExtra = objExtra.IsSkipDetailPage
              ? objExtra.IsSkipDetailPage
              : false
          }
        }

        this.quantity = record.Quantity

        this.objCampaingsSecect = {
          ID: record.ID,
          Name: record.Name,
          NameEN: record.NameEN,
          Detail: record.Detail,
          DetailEN: record.DetailEN,
          TermsAndConditions: record.TermsAndConditions,
          ConditionEN: record.ConditionEN,
          PointPerUnit: record.PointPerUnit,
          PricePerUnit: record.PricePerUnit,
          Quantity: record.Quantity,
          RedeemMostPerPerson: record.RedeemMostPerPerson,
          RedeemMostPerPersonInPeriod: record.RedeemMostPerPersonInPeriod,
          StartDate: moment(),
          EndDate: moment(),
          Status: true,
          AppsVisibility: false,
          UserLevel: record.UserLevel ? record.UserLevel : 0,
          UserVisibility: record.UserVisibility ? record.UserVisibility : 0,
          ImageUrl: record.ImageUrl + `?v=` + new Date().getTime(),
          Extra: objExtra ? objExtra : false,
          IsImported: record.IsImported,
        }
        console.log('objCampaingsSecect', this.objCampaingsSecect)

        if (record.Quantity > 0 && record.Quantity < 1000000) {
          this.optionQuotaTotal = true
        }
        if (record.RedeemMostPerPerson > 0) {
          this.optionQuotaPerson = true
        }
        if (record.RedeemMostPerPersonInPeriod > 0) {
          this.optionCheckInterval = true
          if (
            record.TimeRounding != 'day' &&
            record.TimeRounding != 'week' &&
            record.TimeRounding != 'month'
          ) {
            this.selectedStatus = 'day'
          } else {
            this.selectedStatus = record.TimeRounding
          }
        }
        if (this.userLevelList.length <= 1) {
          this.objCampaingsSecect.UserVisibility = 0
          this.objCampaingsSecect.UserLevel = 0
        }
        this.$nextTick(() => {
          this.isClickEditCampaings = true
          this.isClickDuplicateCampaings = true
          this.campaignDetailDuplicate = true
          this.handleLoading(false)
        })
      }, 1000)
    },
    handleDeleteCampaings(record) {
      Account.bzbsAnalyticTracking(
        'rewards_page',
        'reward_list',
        'click_reward_delete_' + record.Name,
        'on click',
      )
      this.confirmModalAction(
        this.lbl['burnlistdeleteheader'],
        this.lbl['burnlistdeletetitle'],
        'danger',
        this.lbl['confirm-box-cancel-button'],
        true,
        () => {
          Account.bzbsAnalyticTracking(
            'rewards_page',
            'reward_list',
            'click_reward_confirm_delete_' + record.Name,
            'on click',
          )
          this.confirmModalSetting.isOpenModal = false
          this.filter.status.value = ''
          this.searchInput = null
          this.postDeleteCampaings(record)
        },
        () => {
          this.confirmModalSetting.isOpenModal = false
          Account.bzbsAnalyticTracking(
            'rewards_page',
            'reward_list',
            'click_reward_cancel_delete_' + record.Name,
            'on click',
          )
        },
      )
    },
    postDeleteCampaings(record) {
      this.handleLoading(true)
      return new Promise((resolve, reject) => {
        BzbsPlanReward.postBurnCampaingsDelete(record.ID)
          .then(res => {
            this.getCRMPlusBurnCampaings(null).then(result => {
              this.objBurnCampaings = result
              this.totalCampaings = result.length
              for (let i = 0; i < this.objBurnCampaings.length; i++) {
                this.listsequence.push([
                  {
                    id: this.objBurnCampaings[i].ID,
                  },
                ])
                if (this.objBurnCampaings[i].Status == 3) {
                  this.objBurnCampaings[i].StatusActive =
                    this.lbl['plan-reward-point-earn-manage-status-active']
                } else {
                  this.objBurnCampaings[i].StatusActive =
                    this.lbl['plan-reward-point-earn-manage-status-inactive']
                }
              }
              this.handleLoading(false)

              this.alertModalAction(
                this.lbl['alert-box-success-header'],
                this.lbl['plan-reward-campaign-manage-delete-success-message'],
                'success',
                true,
              )
            })
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            reject(error)
          })
      })
    },
    handleCheckInterval(e) {
      this.optionCheckInterval = e.target.checked

      if (this.isClickCreateCampaings) {
        if (!this.optionCheckInterval) {
          this.periodInterval = 0
          this.form.setFieldsValue({
            CreateInterval: this.periodInterval,
          })
        } else {
          this.periodInterval = 1
          this.form.setFieldsValue({
            CreateInterval: this.periodInterval,
          })
        }
        this.$nextTick(() => {
          this.form.validateFields(['CreateInterval'], { force: true })
          this.form.validateFields(['IntervalPeriod'], { force: true })
        })
      }
      if (this.isClickEditCampaings) {
        if (!this.optionCheckInterval) {
          this.objCampaingsSecect.RedeemMostPerPersonInPeriod = 0
          this.form.setFieldsValue({
            EditInterval: this.objCampaingsSecect.RedeemMostPerPersonInPeriod,
          })
        } else {
          this.objCampaingsSecect.RedeemMostPerPersonInPeriod = 1
          this.form.setFieldsValue({
            EditInterval: this.objCampaingsSecect.RedeemMostPerPersonInPeriod,
          })
        }
        this.$nextTick(() => {
          this.form.validateFields(['EditInterval'], { force: true })
        })
      }
    },
    exportSerialCodeListUrl() {
      this.handleLoading(true)
      let response = []
      BzbsPlanReward.getSerialCodeListExportExcel(this.paramgetSerialCodeList)
        .then(res => {
          this.handleLoading(false)
          response = res.data.Serials
          for (let i = 0; i < response.length; i++) {
            if (response[i].UsedOn != null) {
              response[i].UsedOn = this.datetimeShortFormatUTC(
                response[i].UsedOn,
              )
            } else {
              response[i].UsedOn = ''
            }
          }
          const data = _.map(response, item => {
            var Code = this.lbl['view-code-code']
            var User_Id = this.lbl['view-code-user-id']
            var Name = this.lbl['plan-reward-point-burn-manage-name']
            var ContactNumber =
              this.lbl['company-setup-branch-contact-number-placeholder']
            var User_On = this.lbl['view-code-user-on']

            return {
              [Code]: item.Code,
              [User_Id]: item.UserId,
              [Name]: item.FullName,
              [ContactNumber]: item.ContactNumber,
              [User_On]: item.UsedOn,
            }
          })

          if (data.length <= 10000) {
            const dataWS = XLSX.utils.json_to_sheet(data)
            var wscols = [
              { wch: 45 },
              { wch: 45 },
              { wch: 45 },
              { wch: 25 },
              { wch: 25 },
            ]
            dataWS['!cols'] = wscols
            const wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, dataWS)
            XLSX.writeFile(wb, 'export_adjust_success.xlsx')
          } else {
            for (var i = 0; i < Math.ceil(data.length / 10000); i++) {
              var data_WS = XLSX.utils.json_to_sheet(
                data.slice((i + 1 - 1) * 10000, (i + 1) * 10000),
                { dense: true },
              )
              var ws_cols = [
                { wch: 45 },
                { wch: 45 },
                { wch: 45 },
                { wch: 25 },
                { wch: 25 },
              ]
              data_WS['!cols'] = ws_cols
              const wb = XLSX.utils.book_new()
              XLSX.utils.book_append_sheet(wb, data_WS)
              XLSX.writeFile(wb, 'export_adjust_success_' + (i + 1) + '.xlsx')
            }
          }
        })
        .catch(error => {
          this.handleLoading(false)
          console.log('apiGetAppCategoryList error', error)
        })
    },
    changeCreateQuotaTotal(e) {
      this.optionQuotaTotal = e.target.value
      if (this.$refs.file) {
        this.$refs.file.value = null
      }
      if (this.isClickCreateCampaings) {
        if (!this.optionQuotaTotal) {
          this.quotaTotal = 0
        } else {
          this.quotaTotal = 1
        }
        this.form.setFieldsValue({
          CreateQuotaTotal: this.quotaTotal,
        })
        this.$nextTick(() => {
          this.form.validateFields(['CreateQuotaTotal'], { force: true })
        })
      }
      if (this.isClickEditCampaings) {
        if (!this.optionQuotaTotal) {
          this.objCampaingsSecect.RedeemMostPerPerson = 0
        } else {
          this.objCampaingsSecect.RedeemMostPerPerson = 1
        }
        this.form.setFieldsValue({
          EditQuotaTotal: this.objCampaingsSecect.RedeemMostPerPerson,
        })
        this.$nextTick(() => {
          this.form.validateFields(['EditQuotaTotal'], { force: true })
        })
      }
    },
    changeCreateQuotaPerson(e) {
      this.optionQuotaPerson = e.target.value
      if (this.isClickCreateCampaings) {
        if (!this.optionQuotaPerson) {
          this.quotaPerson = 0
        } else {
          this.quotaPerson = 1
        }
        this.form.setFieldsValue({
          CreateQuotaPerson: this.quotaPerson,
        })
        this.$nextTick(() => {
          this.form.validateFields(['CreateQuotaPerson'], { force: true })
        })
      }
      if (this.isClickEditCampaings) {
        if (!this.optionQuotaPerson) {
          this.objCampaingsSecect.RedeemMostPerPerson = 0
        } else {
          this.objCampaingsSecect.RedeemMostPerPerson = 1
        }
        this.form.setFieldsValue({
          EditQuotaPerson: this.objCampaingsSecect.RedeemMostPerPerson,
        })
        this.$nextTick(() => {
          this.form.validateFields(['EditQuotaPerson'], { force: true })
        })
      }
    },
    handleChangeStartDate() {
      if (this.isClickCreateCampaings) {
        if (this.startDatePeriod > this.endDatePeriod) {
          this.endDatePeriod = this.startDatePeriod
        }
      }
      if (this.isClickEditCampaings) {
        if (
          this.objCampaingsSecect.StartDate > this.objCampaingsSecect.EndDate
        ) {
          this.objCampaingsSecect.EndDate = this.objCampaingsSecect.StartDate
        }
      }
    },
    handleChangeEndDate() {
      if (this.isClickCreateCampaings) {
        if (this.endDatePeriod < this.startDatePeriod) {
          this.startDatePeriod = this.endDatePeriod
        }
      }
      if (this.isClickEditCampaings) {
        if (
          this.objCampaingsSecect.EndDate < this.objCampaingsSecect.StartDate
        ) {
          this.objCampaingsSecect.StartDate = this.objCampaingsSecect.EndDate
        }
      }
    },
    changeRedeemLevelList(value) {
      if (this.isClickCreateCampaings) {
        this.objCampaingsAdd.UserLevel = value
      }
      if (this.isClickEditCampaings) {
        this.objCampaingsSecect.UserLevel = value
      }
    },
    changeVisibilityLevelList(value) {
      if (this.isClickCreateCampaings) {
        this.objCampaingsAdd.UserVisibility = value
      }
      if (this.isClickEditCampaings) {
        this.objCampaingsSecect.UserVisibility = value
      }
    },
    copylink() {
      if (this.loginType == 'email') {
        let link = ''
        if (Vue.bzbsConfig.businessRule.webFor.TH) {
          link =
            this.config().bzbsConsumerUrl +
            '/detail/' +
            this.objCampaingsSecect.ID +
            '?app_id=' +
            Cache.get('CRMUser').AppId +
            '&locale=th'

          navigator.clipboard.writeText(link)
          document.getElementById('checkboxshowanimation').checked = true
          setTimeout(() => {
            document.getElementById('checkboxshowanimation').checked = false
          }, 1000)
        } else {
          link =
            this.config().bzbsConsumerUrl +
            '/detail/' +
            this.objCampaingsSecect.ID +
            '?app_id=' +
            Cache.get('CRMUser').AppId +
            '&locale=en'

          navigator.clipboard.writeText(link)
          document.getElementById('checkboxshowanimation').checked = true
          setTimeout(() => {
            document.getElementById('checkboxshowanimation').checked = false
          }, 1000)
        }
      } else {
        var ischecked = document.getElementById('checkboxshowanimation').checked
        if (!ischecked) {
          document.getElementById('checkboxshowanimation').checked = true
          setTimeout(() => {
            document.getElementById('checkboxshowanimation').checked = false
          }, 1000)
        }
        this.$refs.myinput.focus()
        document.execCommand('copy')
      }
    },
    handleChange() {
      this.validateFields()
    },
    clickUploadfilse() {
      let fileInputElement = this.$refs.file
      fileInputElement.click()
    },
    handlekUploadfilse(event) {
      if (this.checkValidate(event.target.files[0])) {
        this.handleLoading(true)
        var file = event.target.files[0]
        BzbsImport.validatePromoCode(file)
          .then(res => {
            this.handleLoading(false)
            var result = res.data
            if (result.isvalid) {
              this.clearerror()
              if (result.count == 0) {
                this.$refs.file.value = null
                this.dispfile = false
                this.errFileUpload = true
              } else {
                this.excelFileName = event.target.files[0].name
                this.quotaTotal = this.quantity + result.count
                this.optionQuotaTotal = true
                this.dispfile = true
                if (this.isClickCreateCampaings) {
                  this.form.setFieldsValue({
                    CreateQuotaTotal: this.quotaTotal,
                  })
                }
                if (this.isClickEditCampaings) {
                  this.form.setFieldsValue({
                    EditQuotaTotal: this.quotaTotal,
                  })
                }
              }
            } else {
              this.clearerror()
              this.$refs.file.value = null
              this.errFileUpload = true
            }
          })
          .catch(error => {
            this.handleLoading(false)
            this.errFileUpload = true
            console.log('validateAdjustFile error', error)
            if (error.response.data.error.code == 409) {
              this.confirmModalAction(
                this.lbl['warning-file-over-size-title'],
                this.lbl['warning-file-over-size-message'],
                'warning',
                this.lbl['confirm-box-cancel-button'],
                true,
                () => {
                  this.confirmModalSetting.isOpenModal = false
                },
                () => {
                  this.confirmModalSetting.isOpenModal = false
                },
              )
            }
          })
        fileReader.getBase64(file).then(data => {
          this.fileListData = {
            Name: 'ValidatePromoCode',
            Data: data,
            FileName: file.name,
            ContentType: file.type,
          }
        })
      }
    },
    removeFileUpload() {
      this.$refs.file.value = null
      this.clearerror()
      this.dispfile = false
      this.quotaTotal = this.quantity
      this.optionQuotaTotal = false
      if (this.isClickCreateCampaings) {
        this.form.setFieldsValue({
          CreateQuotaTotal: this.quotaTotal,
        })
      }
      if (this.isClickEditCampaings) {
        this.form.setFieldsValue({
          EditQuotaTotal: this.quotaTotal,
        })
      }
    },
    clickSelectImageCampaings() {
      this.$refs['inputCampaingsImage'].click()
    },
    clickUploadImage() {
      let fileInputElement = this.$refs.inputCampaingsImage
      fileInputElement.click()
    },
    onInputImageCampaings(event) {
      var fileInput = event.srcElement.files[0]
      if (typeof fileInput === 'undefined') {
        this.errimgUpload = true
        if (this.isClickCreateCampaings) {
          this.pathImage = ''
        }
        if (this.isClickEditCampaings) {
          this.objCampaingsSecect.ImageUrl = ''
        }
        this.pathImage = ''
        this.$refs.inputCampaingsImage.value = null
      } else if (this.checkFileCondition(fileInput)) {
        this.handleLoading(true)
        var fileReader = new FileReader()
        fileReader.onload = e => {
          if (this.isClickCreateCampaings) {
            this.pathImage = e.target.result
          }
          if (this.isClickEditCampaings) {
            this.objCampaingsSecect.ImageUrl = e.target.result
          }
          this.errimgUpload = false
          this.handleLoading(false)
        }
        fileReader.readAsDataURL(fileInput)
      }
    },
    confirmModal() {
      this.objCampaingsSecectForSave = []
      this.objExtralist = {}
      this.objExtralist.IsSkipDetailPage = this.isClickCreateCampaings
        ? this.skipDisplayCampaignPage
        : this.objCampaingsSecect.Extra
      this.objExtralist = JSON.stringify(this.objExtralist)
      if (this.isAllowImportSerial) {
        if (
          (this.isClickCreateCampaings || this.isClickEditCampaings) &&
          this.$refs.file != undefined
        ) {
          this.objCampaingsSecectForSave.serialCodeFile =
            this.$refs.file.files[0]
        }
      }
      if (this.$refs.inputCampaingsImage.files[0]) {
        this.objCampaingsSecectForSave.img =
          this.$refs.inputCampaingsImage.files[0]
      }
      this.objCampaingsSecectForSave.Name = this.isClickCreateCampaings
        ? this.objform.CreateNameTh
        : this.objform.EditNameTh
      this.objCampaingsSecectForSave.NameEN = this.isClickCreateCampaings
        ? this.objform.CreateNameEng
        : this.objform.EditNameEng
      this.objCampaingsSecectForSave.Detail = this.isClickCreateCampaings
        ? this.objform.CreateDetailTh
        : this.objform.EditDetailTh
      this.objCampaingsSecectForSave.DetailEN = this.isClickCreateCampaings
        ? this.objform.CreateDetailEng
        : this.objform.EditDetailEng
      this.objCampaingsSecectForSave.ConditionEN = this.isClickCreateCampaings
        ? this.objform.CreateConditionEN
        : this.objform.EditConditionEN
      this.objCampaingsSecectForSave.TermsAndConditions = this
        .isClickCreateCampaings
        ? this.objform.CreateConditionTh
        : this.objform.EditConditionTh
      this.objCampaingsSecectForSave.PointPerUnit = this.isClickCreateCampaings
        ? this.objform.CreatePointPerUnit
        : this.objform.EditPointPerUnit
      this.objCampaingsSecectForSave.PricePerUnit = this.isClickCreateCampaings
        ? this.objform.CreatePricePerUnit
        : this.objform.EditPricePerUnit
      this.objCampaingsSecectForSave.Quantity = this.isClickCreateCampaings
        ? this.objform.CreateQuotaTotal
        : this.objform.EditQuotaTotal
      this.objCampaingsSecectForSave.RedeemMostPerPerson = this
        .isClickCreateCampaings
        ? this.objform.CreateQuotaPerson
        : this.objform.EditQuotaPerson
      this.objCampaingsSecectForSave.RedeemMostPerPersonInPeriod = this
        .isClickCreateCampaings
        ? this.objform.CreateInterval
        : this.objform.EditInterval
      this.objCampaingsSecectForSave.TimeRounding = this.isClickCreateCampaings
        ? this.objform.IntervalPeriod
        : this.selectedStatus
      this.objCampaingsSecectForSave.Extra = this.objExtralist

      this.objCampaingsSecectForSave.StartDate = this.isClickCreateCampaings
        ? moment(this.startDatePeriod).format(this.dateFormatSave)
        : moment(this.objCampaingsSecect.StartDate).format(this.dateFormatSave)
      this.objCampaingsSecectForSave.StartDate =
        this.objCampaingsSecectForSave.StartDate + 'T00:00:00'

      this.objCampaingsSecectForSave.EndDate = this.isClickCreateCampaings
        ? moment(this.endDatePeriod).format(this.dateFormatSave)
        : moment(this.objCampaingsSecect.EndDate).format(this.dateFormatSave)
      this.objCampaingsSecectForSave.EndDate =
        this.objCampaingsSecectForSave.EndDate + 'T23:59:59'

      this.objCampaingsSecectForSave.Status = this.isClickCreateCampaings
        ? this.campaignStatus
        : this.objCampaingsSecect.Status

      this.objCampaingsSecectForSave.AppsVisibility = this
        .isClickCreateCampaings
        ? this.campaingAppsVisibility
        : this.objCampaingsSecect.AppsVisibility

      let Visibility = null
      if (this.defaultVisibilityLevelList == 0) {
        Visibility = null
      } else {
        Visibility = this.defaultVisibilityLevelList
      }
      let SecectVisibility = null
      if (this.objCampaingsSecect.UserVisibility == 0) {
        SecectVisibility = null
      } else {
        SecectVisibility = this.objCampaingsSecect.UserVisibility
      }

      this.objCampaingsSecectForSave.UserVisibility = this
        .isClickCreateCampaings
        ? Visibility
        : SecectVisibility

      let UserLevel = null
      if (this.defaultUserLevelList == 0) {
        UserLevel = null
      } else {
        UserLevel = this.defaultUserLevelList
      }
      let SecectUserLevel = null
      if (this.objCampaingsSecect.UserLevel == 0) {
        SecectUserLevel = null
      } else {
        SecectUserLevel = this.objCampaingsSecect.UserLevel
      }

      this.objCampaingsSecectForSave.UserLevel = this.isClickCreateCampaings
        ? UserLevel
        : SecectUserLevel

      this.objCampaingsSecectForSave.StoreType = this.storeType

      var lblshowmodal = ''
      if (this.objCampaingsSecectForSave.PointPerUnit > 0) {
        lblshowmodal =
          this.lbl['plan-reward-stamp-campaign-manage-save-confirm-message']
      } else {
        lblshowmodal =
          this.lbl[
            'plan-reward-point-burn-manage-save-confirm-no-point-message'
          ]
      }
      if (this.objCampaingsSecectForSave.PricePerUnit > 0) {
        lblshowmodal =
          this.lbl['plan-reward-stamp-campaign-manage-save-confirm-message']
      } else {
        lblshowmodal =
          this.lbl[
            'plan-reward-point-burn-manage-save-confirm-no-point-message'
          ]
      }
      if (this.isClickCreateCampaings) {
        this.confirmModalAction(
          this.lbl['plan-reward-point-burn-manage-save-confirm-title'],
          lblshowmodal,
          'warning',
          this.lbl['confirm-box-cancel-button'],
          true,
          () => {
            Account.bzbsAnalyticTracking(
              'rewards_page',
              'reward_create',
              'click_confirm_create_campaign',
              'on click',
            )
            this.confirmModalSetting.isOpenModal = false
            this.postCRMPlusBurnCampaingsAdd()
          },
          () => {
            Account.bzbsAnalyticTracking(
              'rewards_page',
              'reward_create',
              'click_cancle_create_campaign',
              'on click',
            )
            this.confirmModalSetting.isOpenModal = false
          },
        )
      }
      if (this.isClickEditCampaings && !this.isClickDuplicateCampaings) {
        this.objCampaingsSecectForSave.ID = this.objCampaingsSecect.ID
        this.postCRMPlusBurnCampaingsEdit()
      } else if (this.isClickEditCampaings && this.isClickDuplicateCampaings) {
        this.objCampaingsSecectForSave.ID = this.objCampaingsSecect.ID
        this.postCRMPlusBurnCampaingsDuplicate()
      }
    },
    validateFields() {
      if (this.isClickEditCampaings) {
        Account.bzbsAnalyticTracking(
          'rewards_page',
          'reward_edit',
          'view_edit_reward_save',
          'on click',
        )
      } else if (this.isClickDuplicateCampaings) {
        Account.bzbsAnalyticTracking(
          'rewards_page',
          'reward_duplicate',
          'view_duplicate_reward_save',
          'on click',
        )
      } else {
        Account.bzbsAnalyticTracking(
          'rewards_page',
          'reward_create',
          'click_create_reward_save',
          'on click',
        )
      }
      this.form.validateFields((err, values) => {
        if (!this.optionCheckInterval) {
          values.IntervalPeriod = 'day'
        }
        if (err) {
          if (this.isClickCreateCampaings && this.pathImage == '') {
            this.errimgUpload = true
          }
          return
        } else {
          if (this.isClickCreateCampaings) {
            if (this.pathImage == '') {
              this.errimgUpload = true
              return
            }
            this.objform = []
            this.objform = values
          }
          if (this.isClickEditCampaings) {
            if (this.objCampaingsSecect.ImageUrl == '') {
              return
            }
          }
          this.objform = []
          this.objform = values
          this.confirmModal()
        }
      })
    },
    statusColor(status) {
      if (status == '3') {
        return 'active'
      } else if (status == '5') {
        return 'waiting'
      } else {
        return 'inactive'
      }
    },
    onlyNumber($event) {
      const regex = /^[0-9]\d*$/
      var match = $event.key.match(regex)

      if (match == null) {
        $event.preventDefault()
      }
    },
    checkValidate(file) {
      this.clearerror()
      var isType = true
      const isSheet =
        file.type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      const isExcel = file.type == 'application/vnd.ms-excel'
      const isCSV = file.type == 'text/csv'
      if (!isSheet && !isExcel && !isCSV) {
        this.errtype = true
        isType = false
      }
      return isType
    },
    checkFileCondition(file) {
      if (
        (file.type !== 'image/jpeg' &&
          file.type !== 'image/jpg' &&
          file.type !== 'image/png') ||
        file.size > this.sizeImage
      ) {
        this.pathImage = require('@/assets/images/blank-photo.png')
        this.$warning({
          title: this.lbl['alert-box-error-header'],
          content: this.imPortImgError,
          centered: true,
          autoFocusButton: null,
          closable: true,
          okText: this.lbl['alert-box-success-close-button'],
          onOk: () => {
            this.$refs.inputCampaingsImage.value = null
            this.errimgUpload = true
          },
        })
        return false
      }
      return true
    },
    /*#endregion Action*/

    /*#region Set Other System*/
    disabledStartDate(current) {
      return current && current.valueOf() < moment().subtract(1, 'days')
    },
    disabledEndDate(current) {
      return current && current.valueOf() <= this.minDate
    },
    resetCondition() {
      if (this.$refs.file) {
        this.$refs.file.value = null
      }
      if (this.$refs.inputCampaingsImage) {
        this.$refs.inputCampaingsImage.value = null
      }
      this.quotaPerson = 0
      this.quotaTotal = 0
      this.periodInterval = 0
      this.objSerialCodeList = []
      this.objCampaingsSecect = []
      this.isClickCreateCampaings = false
      this.isClickEditCampaings = false
      this.visibleSerialCodeList = false
      this.optionQuotaPerson = false
      this.optionQuotaTotal = false
      this.optionCheckInterval = false
      this.selectedStatus = ''
      this.startDatePeriod = moment()
      this.endDatePeriod = moment()
      this.campaignStatus = true
      this.skipDisplayCampaignPage = false
      this.campaingAppsVisibility = false
      this.defaultUserLevelList = 0
      this.defaultVisibilityLevelList = 0
      this.pointPerUnit = 0
      this.pricePerUnit = 0
      this.pathImage = ''
    },
    clearerror() {
      this.dispfile = false
      this.errtype = false
      this.errData = false
      this.errExitsName = false
      this.errPoint = false
      this.errFileUpload = false
      this.errimgUpload = false
      this.objCampaign = []
    },
    imageUrlAlt(event) {
      event.target.src = require('@/assets/images/icon-logo-bzbs.png')
    },
    /*#endregion Set Other System*/

    closeModalCampaignDetail() {
      if (this.isClickEditCampaings) {
        Account.bzbsAnalyticTracking(
          'rewards_page',
          'reward_edit',
          'view_edit_reward_close',
          'on click',
        )
      } else {
        Account.bzbsAnalyticTracking(
          'rewards_page',
          'reward_create',
          'click_create_reward_close',
          'on click',
        )
      }
    },
    closeModalDuplicateCampaignDetail() {
      Account.bzbsAnalyticTracking(
        'rewards_page',
        'reward_duplicate',
        'click_duplicate_reward_close',
        'on click',
      )
    },
  },
}
</script>

<style lang="scss">
@import './styles/planreward.scss';
@import '@/style/base/common.scss';

.wrap-burnList {
  .btn-action {
    padding: 4px 8px;
    border-radius: 8px;
    background-color: #fafafa;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 1px;
    text-align: right;
    color: #424242;
  }
  .titleContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    .title {
      display: flex;
      align-items: center;
    }

    .textTitle {
      font-size: 34px;
      color: #000;
      img {
        vertical-align: sub;
      }
    }
    .search {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .searchContainer {
        flex-grow: 1;
        width: 444px;
        position: relative;
        .ant-input-group-addon {
          .ant-select {
            width: 110px;
          }
        }
        .ant-form-item {
          float: left;
          margin: 0 15px 0 0;
        }
        .ant-btn-primary {
          float: right;
          margin: 0;
          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .box-filter {
    padding: 16px 30px;
    .btn-filter {
      i {
        font-size: 24px;
        vertical-align: middle;
        color: $color-grey-75;
      }
    }
  }

  .wrap-table {
    .btn-sequence {
      padding: 10px;
      width: 40px;
      cursor: move;
      span {
        width: 100%;
        height: 2px;
        display: block;
        background: #000;
        + span {
          margin-top: 2px;
        }
      }
    }
    .ant-table-wrapper {
      .ant-table-pagination {
        .ant-pagination {
          margin: 16px 0;
        }
      }

      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        font-size: 10px;
        &:hover {
          color: $primary-hover !important;
          border-color: $primary-hover !important;
        }
      }

      .ant-select-dropdown-menu-item:hover,
      .ant-select-dropdown-menu-item-selected {
        background-color: #fff7e6;
      }

      .ant-pagination-item-active,
      .ant-pagination-item:focus,
      .ant-pagination-item:hover {
        border-color: $primary-hover !important;
      }

      i.anticon.anticon-left {
        vertical-align: 0;
      }

      i.anticon.anticon-right {
        vertical-align: 0;
      }
    }
    .ant-input-number {
      height: 40px;
    }
    .ant-input-number-input-wrap {
      height: 100%;
    }
    .ant-input-number-handler-wrap {
      visibility: hidden;
    }
    .ant-input-number-input {
      padding: 0 11px !important;
      height: 100%;
    }
    .form-button {
      text-align: right;
    }
    .status {
      line-height: 24px;
      padding: 0 4px;
      font-size: 14px;
      border-radius: 4px;
      &.active {
        color: $color-active;
        background: $color-bg-active;
      }
      &.inactive {
        color: $color-inactive;
        background: $color-bg-inactive;
      }
    }
    .reward {
      display: flex;
      align-items: center;
      .reward-image {
        margin: 0 5px;
        width: 50px;
        height: 50px;
      }
      .reward-name {
        flex-grow: 1;
        padding-left: 10px;
      }
    }
    .viewcode {
      cursor: pointer;
      text-align: center;
      color: $color-link;
    }
    .disablestyle {
      color: #a6a8ab !important;

      .reward-image {
        opacity: 0.5;
      }
      .viewcode {
        opacity: 0.5;
      }
    }
  }
}
.wrap-burnList
  .wrap-table
  .ant-table-wrapper
  .ant-pagination-next
  .ant-pagination-item-link,
.wrap-burnList
  .wrap-table
  .ant-table-wrapper
  .ant-pagination-prev
  .ant-pagination-item-link {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
}

.modal-serial-codelist {
  .ant-table-pagination.ant-pagination {
    margin: 16px 0 16px;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    font-size: 10px;
    &:hover {
      color: $primary-hover !important;
      border-color: $primary-hover !important;
    }
  }

  .ant-select-dropdown-menu-item:hover,
  .ant-select-dropdown-menu-item-selected {
    background-color: #fff7e6;
  }

  .ant-pagination-item-active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover {
    border-color: $primary-hover !important;
  }
  i.anticon.anticon-left {
    vertical-align: 0;
  }

  i.anticon.anticon-right {
    vertical-align: 0;
  }
  .ant-input-number {
    height: 40px;
  }
  .ant-input-number-input-wrap {
    height: 100%;
  }
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
  .ant-input-number-input {
    padding: 0 11px !important;
    height: 100%;
  }

  background: #fff;
  .wrap-table {
    width: 75%;
    margin: 15px auto 0;
  }

  .ant-table-body {
    overflow-x: auto;
    white-space: nowrap;
  }
  .div1 {
    float: left;
  }
  .div2 {
    float: left;
    margin-left: 10px;
  }
  .div3 {
    float: right;
  }
  .div4 {
    float: right;
    width: 80%;
  }
  .ant-modal {
    width: 90% !important;
    max-width: 1140px;
  }

  .ico-excel {
    background-color: #217346;
    height: 1.5vw;
    padding: 0.25vw;
  }

  .button-import {
    height: 45px;
    padding: 5px;
    width: 130px;
    background-color: #ebecf0;
    border-color: #ebecf0;
    color: #000;
  }

  .button-import:hover {
    background-color: #e5e5e5;
    border-color: #e5e5e5;
  }

  .div-export-serialcodelist {
    float: left;
    margin-bottom: 25px;
  }

  .ant-table-body {
    overflow-x: auto;
    white-space: nowrap;
  }
}
.modal-campaign-modal {
  .has-error-price {
    color: $color-red-f5;
    text-align: left;
  }
  .copylink {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
  }
  .copylink:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }
  .ant-btn.ant-btn-primary-disabled,
  .ant-btn.ant-btn-primary.disabled,
  .ant-btn.ant-btn-primary[disabled] {
    color: #fff;
    background-color: #c4c4c4;
    border-color: #c4c4c4;
  }
  .field-row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .div1 {
    float: left;
  }
  .div2 {
    float: left;
    margin-left: 10px;
  }
  .div3 {
    float: right;
  }
  .div4 {
    float: right;
    width: 80%;
  }
  .ant-modal {
    width: 90% !important;
    max-width: 1140px;
  }
  .ant-form-item-label {
    float: left;
    width: 20%;
    text-align: left;
  }
  .ant-form-item-control-wrapper {
    float: left;
    width: 80%;
  }
  .ant-form-item-required::before {
    display: none;
  }
  .ant-form-item-required::after {
    display: inline-block;
    margin-right: 4 px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-form-item-with-help {
    margin-bottom: 0px;
  }
  .div1 {
    float: left;
  }
  .div2 {
    float: left;
    margin-left: 10px;
  }
  .ant-input-number {
    height: 40px;
    width: 183px;
    float: left;
    margin-right: 15px;
  }
  .ant-input-number-input-wrap {
    height: 100%;
  }
  .ant-input-number-handler-wrap {
    visibility: hidden;
  }
  .ant-input-number-input {
    padding: 0 11px !important;
    height: 100%;
  }
  .ant-form-explain {
    text-align: left;
  }
  .quota {
    .ant-form-item-control-wrapper {
      float: left;
      width: 100%;
    }
    .ant-form-item {
      float: left;
    }
  }
  .interval {
    .ant-form-item {
      float: left;
      margin-right: 5px;
    }
    .ant-form-item-control-wrapper {
      width: 100%;
      text-align: left;
    }
  }
  .btnsubmit {
    border-top: 1px solid #c9c9c9;
    padding-top: 15px;
    .ant-form-item {
      width: 100%;
    }
    .ant-form-item-control-wrapper {
      width: 100%;
      text-align: right;
    }
  }
  img.delete-image {
    width: 15px;
    height: auto;
    cursor: pointer;
  }
  .ico-excel {
    background-color: #217346;
    height: 2vw;
    padding: 0.25vw;
  }
  .txt-left {
    text-align: left;
  }
  .active {
    .ant-input-number-input-wrap {
      display: none;
    }
    .ant-select-selection__rendered {
      display: none;
    }
  }
  .period {
    .ant-calendar-picker-clear {
      display: none;
    }
  }

  .msg {
    display: block;
    margin-top: 8px;
    padding: 0 8px;
    border: 1px solid #ddd;
    background: rgb(0, 0, 0, 0.5);
    color: #fff;
    border-radius: 3px;
    font-size: 16px;
    border-radius: 4px;
  }
  .showanimation {
    position: absolute;
    top: -55px;
    margin-left: 10px;
  }
  .trigger {
    .checkboxshow {
      display: none;
    }
    input[type='checkbox'] {
      // Hide content via default
      & + span {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 0.33s, opacity 0.33s linear;
      }

      // Show if checkbox is clicked
      &:checked + span {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }
}
.disable-button {
  color: rgba(0, 0, 0, 0.25) !important;
}
@media only screen and (max-width: 570px) {
  .searchContainer {
    width: 100% !important;
  }
}
</style>
